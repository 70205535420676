import React, { Component } from "react";
import "./styles.css";

class Intro extends Component {

    render() {
        return (
            <p className="Intro">{this.props.children}</p>
        );
    }
}

export default Intro;