import React, { Component } from "react";
import "./styles.css";
import ListItem from "../ListItem";
import Dropdown from "../Dropdown";
import DefaultLogo from "./default.png";
import Keys from "../../Keys";
import Header from "../Header";
import Container from "../Container";

class ListContainer extends Component {

    constructor(props) {
        super(props);
        this.state = { activeSort: this.props.sorts ? 0 : void 0 };
    }

    _fetchItems = function () {
        if (!this.props.items) {
            return [];
        }
        var elements = [];
        // Props are immutable so it's needed to create a new array and do the changes to it
        var items = !this.props.sorts ? this.props.items : [].concat(this.props.items).sort(this.props.sorts[this.state.activeSort].sort);
        for (var i = 0, len = items.length; i < len; i++) {
            var item = items[i];
            elements.push(<ListItem color={item.color} notAvailableText={this.props.notAvailableText} key={Keys.argsToKey("li-for", item.textContent || item.textLines[0], i)} priority={item.priority} image={item.image} logo={item.logo || DefaultLogo} handler={item.handler} textContent={item.textContent} textLines={item.textLines} />);
        }
        return elements;
    }

    _changeSort = function (index) {
        this.setState({ activeSort: index });
    }

    _fetchSorts = function () {
        if (!this.props.sorts || this.props.sorts.length < 2) {
            return [];
        }
        var elements = [];
        for (var i = 0, len = this.props.sorts.length; i < len; i++) {
            var sort = this.props.sorts[i];
            elements.push({ textContent: sort.name, onClick: this._changeSort.bind(this, i) });
        }
        return <Dropdown items={elements} />;
    }

    render = function () {
        return (
            <Container textAlignCenter={true}>
                <Header title={this.props.title} logo={this.props.logo} type={Header.Type.REGULAR}
                    onLeftClick={this.props.onLeftClick} leftClickText={this.props.leftClickText}
                    onRightClick={this.props.onRightClick} rightClickText={this.props.rightClickText} />
                {this.props.intro && <p className="ListContainer-intro">{this.props.intro}</p>}
                {this.props.bgImage && <img src={this.props.bgImage} alt="Background" className={"ListContainer-bg-image"} />}
                {this.props.sorts && this._fetchSorts.call(this)}
                {this.props.items && <div className={this.props.bgImage ? "ListContainer-items-with-image" : "ListContainer-items"}>{this._fetchItems.call(this)}</div>}
                {this.props.children && <div className="ListContainer-children">{this.props.children}</div>}
            </Container>
        );
    }
}

export default ListContainer;