import React, { Component } from "react";
import "./styles.css";
import Header from "../Header";
import Container from "../Container";
import Intro from "../Intro";

const languageIconsFolder = "./languageIcons/";
const languageIcons = {
    en: require(languageIconsFolder + "en.png"),
    pt: require(languageIconsFolder + "pt.png")
};

class HomeContainer extends Component {

    // https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
    // http://detectmobilebrowsers.com/
    // @TODO: Try switching to a more normalized verification if possible
    _isPhone = function () {
        (function (a) {
            return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0, 4));
        })(navigator.userAgent || navigator.vendor || window.opera);
    }

    _fetchGoogleHref = function () {
        return (this._isPhone() ? "market://details?id=" : "https://play.google.com/store/apps/details?id=") + this.props.marketId.google;
    }

    _fetchAppleHref = function () {
        return "https://itunes.apple.com/app/id" + this.props.marketId.apple;
    }

    _renderLanguageIcons = function () {
        if (!this.props.languages || this.props.languages.length === 0) {
            return void 0;
        }
        var icons = [];
        for (var i = 0, len = this.props.languages.length; i < len; i++) {
            var language = this.props.languages[i];
            if (languageIcons[language.key]) {
                icons.push(
                    <button className={"HomeContainer-languageButton"} key={language.key + i} onClick={language.active ? void 0 : language.handler}>
                        <img className={language.active ? "HomeContainer-language HomeContainer-languageActive" : "HomeContainer-language"} src={languageIcons[language.key]} alt={"Country flag for language " + language.key} />
                    </button>
                );
            } else {
                console.warn("Language '" + language.key + "' not supported.");
            }
        }
        return (<div className="HomeContainer-languageContainer">{icons}</div>);
    }

    render = function () {
        return (
            <Container>
                {(this.props.title || this.props.logo) && <Header logo={this.props.logo} title={this.props.title} type={Header.Type.MAIN} />}
                <div className="HomeContainer-vertically-centered">
                    {this.props.intro && <Intro>{this.props.intro}</Intro>}
                    {this._renderLanguageIcons()}
                    {this.props.banner && <img className="HomeContainer-banner" onClick={this.props.bannerHandler} alt={"Page banner for " + this.props.title} src={this.props.banner} />}
                    {this.props.marketId && this.props.marketId.google && <div><a href={this._fetchGoogleHref()}><img src={require("./google-play-badge.png")} className="HomeContainer-badge" alt="Google play badge, redirects to the store page." /></a></div>}
                    {this.props.marketId && this.props.marketId.apple && <div><a href={this._fetchAppleHref()}><img src={require("./app-store-badge.png")} className="HomeContainer-badge" alt="App store badge, redirects to the store page." /></a></div>}
                    {this.props.children && <div className="HomeContainer-children">{this.props.children}</div>}
                    {this.props.footer && <hr className="HomeContainer-hr" />}
                    {this.props.footer && this.props.footer}
                </div>
            </Container>
        );
    }
}

export default HomeContainer;