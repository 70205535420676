import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import routeSetter from "./route-setter";
import StaticNavbar from "./Components/StaticNavbar";
import Maintenance from "./Components/Maintenance";
import Toast from "./Components/Toast/Toast";
import fetchItems from "./items";
import Data from "./Data";
import Strings from "./Strings";
import PageNotFoundContainer from "./HashRouter/PageNotFoundContainer";
import HashRouter from "./HashRouter";
import httpsForcer from "./https-forcer";
import disableReactTools from "./disable-react-tools";
import LoadingContainer from "./Components/LoadingContainer";
import showWelcomeMessage from "./welcome-message";
import * as serviceWorker from './serviceWorker';
import "./Pollyfills";

const config = require("./config");

const initial = config.initialItem;

var init = function (data) {

    ReactDOM.render(<Maintenance />, document.getElementById("maintenance"));
    if (config.dev) {
        console.log("Carregados os dados: ", data);
        window.data = data;
    }
    var items = fetchItems(data, init);
    routeSetter({ items, data, init });
    if (HashRouter.hasHash()) {
        HashRouter.renderCurrent();
    } else {
        HashRouter.goTo(initial);
    }
    ReactDOM.render(<StaticNavbar home={initial} items={items} />, document.getElementById("navbar"));
    serviceWorker.unregister();
};

var onServerError = function (err, text) {
    Toast.show(text, { type: "error" });
    config.dev && console.warn(err);
    ReactDOM.render(<PageNotFoundContainer title="Erro" intro={text} />, document.getElementById("root"));
};

(function () {
    if (!config.dev) {
        httpsForcer();
        disableReactTools();
    }
    showWelcomeMessage();
    ReactDOM.render(<LoadingContainer />, document.getElementById("root"));
    ReactDOM.render(<Toast />, document.getElementById("alerts"));
    Data.loadData(function (err, data) {
        if (err) {
            return onServerError(err, config.dataLoadingError);
        }
        Strings.fetch(data, function (err) {
            if (err) {
                return onServerError(err, config.languageLoadingError);
            }
            init(data);
        });
    });
})();
