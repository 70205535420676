const Type = {
    MAIN: "Main",
    REGULAR: "Regular"
};

const Default = {
    LEFT_TEXT: "<<",
    RIGHT_TEXT: ">>"
};

export default {
    Type,
    Default
};