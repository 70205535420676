import React, { Component } from "react";
import ReactDOM from "react-dom";
import Toast from "../Toast/Toast";
import Keys from "../../Keys";

import "./styles.css";

const defaultNotAvailableText = "Soon available";
const defaultColor = "#007E40";
const defaultDisabled = "#343434";

class ListItem extends Component {

    _fetchTextLines = function () {
        var elements = [];
        for (var i = 0, len = this.props.textLines.length; i < len; i++) {
            if (this.props.textLines[i]) {
                elements.push(<div key={Keys.argsToKey("li-line-for", this.props.textLines[i], i)} className={i === 0 ? "ListItem-text ListItem-bold" : "ListItem-text"}>{this.props.textLines[i]}</div>);
            }
        }
        return elements;
    }

    _onClick = function (handler) {
        if (handler) {
            typeof handler === "function" ? handler() : ReactDOM.render(handler, document.getElementById('root'));
        } else {
            Toast.show(this.props.notAvailableText || defaultNotAvailableText, { type: "error" });
        }
    }

    render = function () {
        var className = this.props.handler ? "ListItem" : "ListItem ListItem-disabled";
        this.props.priority && (className += " ListItem-priority");
        return (
            <div onClick={this._onClick.bind(this, this.props.handler)} className={className} style={{ backgroundColor: this.props.color || (this.props.handler ? defaultColor : defaultDisabled) }} >
                {this.props.logo && <img className="ListItem-logo" alt={this.props.alt || "A simple list bullet"} src={this.props.logo} />}
                <div className="ListItem-textbox">
                    {this.props.textContent && <div className="ListItem-text">{this.props.textContent}</div>}
                    {this.props.textLines && this._fetchTextLines.call(this)}
                </div>
                {this.props.image && <img className="ListItem-image" alt="Represents a list item" src={this.props.image} />}
            </div>
        );
    }
}

export default ListItem;