const dev = false;

// External
var location = window.location;
const mainUrl = dev ? "http://teste.caminhosdesantiago.pt/" : location.href.substring(0, location.href.indexOf("caminhos")) + "caminhosdesantiago.pt/";
const baseBlob = mainUrl + "blob/";
// Local
const resPath = "./res/";
const placeTypesPath = resPath + "place_types/";
const navIconsPath = resPath + "nav_icons/";

const homeUrl = "home";

module.exports = {
    dev,
    localStorageLangKey: "lang",
    welcomeMessage: ["%cFound an error❌ or a security bug🐛? Contact us at %ccast@cast.pt", "font-size: 18px;", "font-size: 18px; color: rgb(0,87,240);"],
    mainUrl,
    initialItem: homeUrl,
    homeUrl,
    pathsUrl: "paths",
    pointsUrl: "points",
    dormitoriesUrl: "dormitories",
    recaptchaSiteKey: "6Leeu4gUAAAAAPrweB5_bb69E4myZtkJDxNRLEb9",
    foodsUrl: "foods",
    otherUrl: "other",
    navUrl: "nav",
    loginUrl: "login",
    logoutUrl: "logout",
    signupUrl: "signup",
    profileUrl: "profile",
    deleteUrl: "delete",
    updateUrl: "edit",
    baseBlob,
    weather: {
        iconsPath: baseBlob + "weather_icons/",
        apiEndpoint: mainUrl + "weather/forecast/{lat}/{lng}",
    },
    authenticationEndpoints: {
        login: mainUrl + "login",
        logout: mainUrl + "logout",
        signup: mainUrl + "signup",
        update: mainUrl + "api/user",
        delete: mainUrl + "api/user"
    },
    routeCoordinates: {
        apiEndPoint: "pth_route_coordinate"
    },
    navIcons: {
        home: require(navIconsPath + "home.png"),
        dormitories: require(navIconsPath + "dormitories.png"),
        foods: require(navIconsPath + "foods.png"),
        nav: require(navIconsPath + "nav.png"),
        other: require(navIconsPath + "other.png"),
        paths: require(navIconsPath + "paths.png"),
        login: require(navIconsPath + "login.png"),
        logout: require(navIconsPath + "logout.png"),
        signup: require(navIconsPath + "signup.png"),
        profile: require(navIconsPath + "profile.png")
    },
    icons: {
        // Place types
        accomodation: require(placeTypesPath + "accomodation.png"),
        bar: require(placeTypesPath + "bar.png"),
        busStop: require(placeTypesPath + "bus_stop.png"),
        groceryStore: require(placeTypesPath + "grocery_store.png"),
        hospital: require(placeTypesPath + "hospital.png"),
        monument: require(placeTypesPath + "monument.png"),
        museum: require(placeTypesPath + "museum.png"),
        other: require(placeTypesPath + "other.png"),
        pharmacy: require(placeTypesPath + "pharmacy.png"),
        postOffice: require(placeTypesPath + "post_office.png"),
        religiousBuilding: require(placeTypesPath + "religious_building.png"),
        touristOffice: require(placeTypesPath + "tourist_office.png"),
        townHall: require(placeTypesPath + "town_hall.png"),
        trainStation: require(placeTypesPath + "train_station.png"),
        food: require(placeTypesPath + "food.png"),
        // Other
        kml: require(resPath + "kml.png"),
        gpx: require(resPath + "gpx.png"),
        tracks: require(resPath + "generic_track.png"),
        paths: require(resPath + "generic_path.png"),
        facebook: require(resPath + "facebook.png"),
        website: require(resPath + "website.png")
    },
    trackDetailsPolylineColor: "#007E40",
    overviewPathsImage: require(resPath + "paths.png"),
    homeBanner: require(resPath + "banner.png"),
    marketId: { google: "pt.cast.caminhosdesantiago", apple: "1436155667" },
    temperatureProperty: "main.temp",
    polylineColors: ["#004b99", "#ffd300"],
    deactivatedColor: "#a9a9a9",
    priceUnit: "€",
    dataLoadingError: "Falha ao carregar os dados do servidor.",
    languageLoadingError: "Falha ao carregar idiomas."
};
