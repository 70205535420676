import React, { Component } from "react";
import "./styles.css";

class FormField extends Component {

    constructor(props) {
        super(props);
        this.state = { hideTopLabel: true, value: props.value || void 0 };
        if (props.id === void 0) {
            console.warn("All the form fields require the 'id' property in order to be distinguished by the parent 'Form'.");
        }
    }

    _onInputFocus = function () {
        this.setState({ hideTopLabel: false });
    }

    _getPlaceholder = function () {
        return this.state.hideTopLabel ? this.props.label : "";
    }

    _onInputBlur = function () {
        if (!this.state.value) {
            this.setState({ hideTopLabel: true });
        }
    }

    _onInputChange = function (event) {
        var newValue = event.target.value;
        if (!this.props.onChange) {
            return console.warn("No parent associated with the form " + this.props.id);
        }
        this.props.onChange(this.props.id, newValue, function () {
            this.setState({ value: newValue });
        }.bind(this));
    }

    _getLabel = function () {
        return this.props.required ? this.props.label + "*" : this.props.label;
    }

    render = function () {
        return (
            <div className="FormField">
                <label className={this.state.hideTopLabel ? "FormField-label" : "FormField-label FormField-labelVisible"}>{this.props.label}</label>
                <input placeholder={this._getPlaceholder.call(this)} onChange={this._onInputChange.bind(this)} required={!!this.props.required} onBlur={this._onInputBlur.bind(this)} onFocus={this._onInputFocus.bind(this)} className="FormField-input" type={this.props.type || "text"} value={this.state.value} />
            </div>
        );
    }
}

export default FormField;