import Data from "./Data";
import Strings from "./Strings";

const config = require("./config");

var getMostImportantContact = function (contacts) {
    return contacts.length > 0 ? Data.sortAsc(contacts, "type")[0].description : void 0;
};

var getDifficultyLevel = function (data, id) {
    return Strings.get(Data.whereId(data.difficulties, id).description);
};

var getManagementType = function (data, id) {
    return Strings.get(Data.whereId(data.accomodationManagementTypes, id).description);
};

var getAccomodationType = function (data, id) {
    return Strings.get(Data.whereId(data.accomodationTypes, id).description);
};

var getPlaceType = function (data, id) {
    return Strings.get(Data.whereId(data.placeTypes, id).description);

};

var getPlaceInfo = function (data, id) {
    return Strings.get(Data.whereId(data.places, id).information);
};

var getTrackPointsMarkers = function (data, trackId) {
    var markers = [];
    data.trackPoints.forEach(function (trackPoint) {
        if (trackId === void 0 || trackPoint.track === trackId) {
            var point = Data.whereId(data.points, trackPoint.point);
            // @TODO: REMOVE BIT SUPPORT, ALLOW ONLY BOOLEAN SUPPORT
            var isActive;
            if (Array.isArray(point["show_on_map"].data)) { // Bit array
                isActive = point["show_on_map"].data[0];
            } else { // Boolean
                isActive = point["show_on_map"];
            }
            if (isActive) {
                markers.push({ type: "yellow", lat: point.latitude, lng: point.longitude, popup: Data.whereId(data.localizations, point.localization).description });
            }
        }
    });
    return markers;
};

var getLogo = (function () {
    var defaultLogo = config.icons.other;
    var placeTypeLogos = {
        "place_type_1_description": config.icons.touristOffice, // Tourist office
        "place_type_2_description": config.icons.hospital, // Hospital
        "place_type_3_description": config.icons.busStop, // Bus Stop
        "place_type_4_description": config.icons.trainStation, // Train Station
        "place_type_5_description": config.icons.museum, // Museum
        "place_type_6_description": config.icons.postOffice, // Post Office
        "place_type_7_description": config.icons.townHall, // Town Hall
        "place_type_8_description": defaultLogo, // Taxi Stand @TODO: No icon provided by the designer
        "place_type_9_description": config.icons.food, // Restaurant
        "place_type_10_description": config.icons.groceryStore, // Grocery Store
        "place_type_11_description": config.icons.accomodation, // Accommodation
        "place_type_12_description": config.icons.bar, // Bar
        "place_type_13_description": config.icons.religiousBuilding, // Religious Building
        "place_type_14_description": config.icons.pharmacy, // Pharmacy
        "place_type_15_description": config.icons.monument // Monument
    };
    return function (data, placeType) {
        var placeTypeDescription = Data.whereId(data.placeTypes, placeType).description;
        if (placeTypeLogos[placeTypeDescription]) {
            return placeTypeLogos[placeTypeDescription];
        }
        return defaultLogo;
    }
})();

var getNationalityOptions = function (data) {
    var options = [];
    Data.iterate(data.nationalities, function (nationality) {
        nationality.priority !== 1 && options.push({ label: Strings.get(nationality.description), favorite: nationality.priority, value: nationality.id });
    });
    return Data.sort(options, [{ property: "favorite", reverse: true }, "label"]);
};

var getLanguageOptions = function (data) {
    var options = [];
    options.push({ label: Strings.get("AUTO"), value: null });
    Data.iterate(data.languagePacks, function (languagePack) {
        options.push({ label: Strings.get(languagePack.description), value: languagePack.id });
    });
    return Data.sort(options, ["label"]);
};

export default {
    getMostImportantContact,
    getDifficultyLevel,
    getManagementType,
    getAccomodationType,
    getTrackPointsMarkers,
    getPlaceType,
    getPlaceInfo,
    getNationalityOptions,
    getLanguageOptions,
    getLogo
}
