import React, { Component } from "react";
import "./styles.css";

class PageNotFoundContainer extends Component {
    render = function () {
        return (
            <div className="PageNotFoundContainer">
                <header className="PageNotFoundContainer-header">
                    <h1 className="PageNotFoundContainer-title">{this.props.title}</h1>
                </header>
                <div className="PageNotFoundContainer-vertically-centered">
                    {this.props.intro && <p className="PageNotFoundContainer-intro">{this.props.intro}</p>}
                    <img className="PageNotFoundContainer-banner" alt={"Page banner for " + this.props.title} src={require("./banner.png")} />
                </div>
            </div>
        );
    }
}

export default PageNotFoundContainer;