import React, { Component } from "react";
import "./styles.css";
import StaticNavbarItem from "./StaticNavbarItem";
import HashRouter from "../../HashRouter";
import Keys from "../../Keys";

class StaticNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: HashRouter.getElement(0) || props.home
        };
        window.addEventListener("hashchange", this._onHashChange.bind(this));
    }

    _onHashChange = function () {
        var key = HashRouter.getElement(0);
        this.setState(key && this.props.items[key] ? { active: key } : { active: void 0 });
    }

    _openContainer = function (key) {
        this.setState({ active: key });
        HashRouter.goTo(key);
    }

    _fetchNavbarItems() {
        var items = [];
        for (var key in this.props.items) {
            var item = this.props.items[key];
            items.push(<StaticNavbarItem src={item.icon} onClick={this._openContainer.bind(this, key)} key={Keys.argsToKey("navbar-item", key)} id={key} active={key === this.state.active} side={item.side} alt={item.name}/>);
        }
        return items;
    }

    render() {
        return (
            <nav><ul className="StaticNavbar">
                {this._fetchNavbarItems()}
                {this.props.children}
            </ul></nav>
        );
    }
}

export default StaticNavbar;