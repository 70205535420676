import React, { Component } from "react";
import "./styles.css";
import HashRouter from "../../HashRouter";
import Keys from "../../Keys";

const DEFAULT_RETURN_TEXT = "RETURN";

class TabContainer extends Component {

    _fetchCurrent = function () {
        var element = this.props.items[this._getActive()].renderable;
        return <div>{element}</div>;
    }

    _tabItemHandler = function (elementIndex) {
        HashRouter.goTo(HashRouter.getHash() + "/" + elementIndex);
        this.forceUpdate();
    }

    _getActive = function () {
        return parseInt(HashRouter.getSubElement(0), 10) || 0;
    }

    _fetchTabItems = function () {
        if (!this.props.items) {
            return void 0;
        }
        var items = [];
        var len = this.props.items.length;
        this.props.onReturn && len++;
        var singleElementWidth = "" + (Math.floor((100 - (len * 10)) / len)) + "%";
        this.props.onReturn && items.push(<div style={{ width: singleElementWidth }} onClick={this.props.onReturn} key={Keys.argsToKey("tab-return")} className="TabContainer-tabItem">{this.props.onReturnText || DEFAULT_RETURN_TEXT}</div>);
        for (var i = 0; i < this.props.items.length; i++) {
            var item = this.props.items[i];
            items.push(<div style={{ width: singleElementWidth }} onClick={this._tabItemHandler.bind(this, i)} key={Keys.argsToKey("tab-for", item.textContent, i)} className={this._getActive() === i ? "TabContainer-tabItem TabContainer-activeTabItem" : "TabContainer-tabItem"}>{item.textContent}</div>);
        }
        return items;
    }

    _fetchMarginBottom = function () {
        return 53 + (this.props.items.length * 29);
    }

    render() {
        return (
            <div>
                <div id="TabContainer-tab">
                    {this._fetchTabItems.call(this)}
                </div>
                <div style={{ marginBottom: this._fetchMarginBottom() }} className="TabContainer">
                    {this._fetchCurrent.call(this)}
                </div>
            </div>
        );
    }
}

export default TabContainer;