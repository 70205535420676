import React, { Component } from "react";
import ReactRecaptcha from "react-recaptcha";
import "./styles.css";

class FormRecaptcha extends Component {

    constructor(props) {
        super(props);
        this.state = { value: void 0 };
        if (props.id === void 0) {
            console.warn("All the form fields require the 'id' property in order to be distinguished by the parent 'Form'.");
        }
    }

    _verifyCallback = function (response) {
        if (!this.props.onChange) {
            return console.warn("No parent associated with the form " + this.props.id);
        }
        this.props.onChange(this.props.id, response, function () {
            this.setState({ value: response });
        }.bind(this));
    }

    render = function () {
        return (
            <div className="FormRecaptcha">
                <ReactRecaptcha className="FormRecaptcha-content" key={this.props.id} sitekey={this.props.sitekey} render="explicit" verifyCallback={this._verifyCallback.bind(this)} />
            </div>
        );
    }
}

export default FormRecaptcha;