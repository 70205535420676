import React, { Component } from "react";
import Container from "../Container";
import MapView from "../MapView";

class NavigationContainer extends Component {
    render() {
        return (
            <Container>
                {/* <Header logo={this.props.logo} title={this.props.title} type={Header.Type.REGULAR} /> */}
                <MapView {...this.props} />
            </Container>
        );
    }
}

export default NavigationContainer;