const config = require("./config");
const errorStatus = config.treatAsErrorWhen.status;
const errorResponses = config.treatAsErrorWhen.response;

var fetchedData = {};
var user;

var isURL = (function () {
    var pattern = /^(https?):\/\/((?:[a-z0-9.-]|%[0-9A-F]{2}){3,})(?::(\d+))?((?:\/(?:[a-z0-9-._~!$&'()*+,;=:@]|%[0-9A-F]{2})*)*)(?:\?((?:[a-z0-9-._~!$&'()*+,;=:?@]|%[0-9A-F]{2})*))?(?:#((?:[a-z0-9-._~!$&'()*+,;=:?@]|%[0-9A-F]{2})*))?$/i;
    return function (tester) {
        return pattern.test(tester);
    }
})();

var isError = function (response) {
    for (var i = 0, lenI = errorStatus.length; i < lenI; i++) {
        var status = errorStatus[i];
        if (status.test(response.status.toString())) {
            return true;
        }
    }
    for (var j = 0, lenJ = errorResponses.length; j < lenJ; j++) {
        var res = errorResponses[j];
        if (res.test(response.response)) {
            return true;
        }
    }
    return false;
};

var fetchData = function (element, callback, verb, data) {
    var xhr = new XMLHttpRequest();
    xhr.open(verb || "GET", isURL(element) ? element : config.apiEndPoint + element);
    xhr.responseType = "json";
    xhr.addEventListener("error", callback);
    xhr.addEventListener("abort", callback);
    xhr.addEventListener("load", function () {
        var responseJson;
        // If responseType is not "json" it means the browser did not accept the "json" type previously.
        if (xhr.responseType.toUpperCase() !== "JSON") {
            try {
                responseJson = JSON.parse(this.response);
            } catch (e) {
                responseJson = this.response;
            }
        } else {
            responseJson = this.response;
        }
        responseJson && responseJson.content && (responseJson = responseJson.content);
        return isError(this) ? callback(responseJson, null) : callback(null, responseJson);
    });
    xhr.setRequestHeader("Pragma", "no-cache");
    xhr.setRequestHeader("Cache-Control", "no-cache, no-store, must-revalidate");
    if (data) {
        xhr.setRequestHeader("Credentials", "include");
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhr.send(JSON.stringify(data));
    } else {
        xhr.send();
    }
};

var fetchAsync = function (fetchArray, callback) {
    var fetchesRemaining = fetchArray.length;
    var error = null;
    var data = {};
    fetchArray.forEach(function (toFetch) {
        // If it's configured to be cached and currently has cache
        // there's no need to fetch
        if (toFetch.cache && fetchedData[toFetch.key]) {
            fetchesRemaining--;
            // To avoid multiple callback, we only run callback
            // if there's no error (because if there's an error, a callback
            // sending the error was already sent).
            if (!error) {
                data[toFetch.key] = fetchedData[toFetch.key];
                fetchesRemaining === 0 && callback && callback(error, data);
            }
        } else {
            fetchData(toFetch.endpoint, function (err, receivedData) {
                fetchesRemaining--;
                if (err) {
                    // If we're here, it means the next fetches will be ignored
                    // (no callback), since we already sent the error callback here.
                    error = err;
                    return callback && callback(err, data);
                }
                // If there's an error, it's pointless to keep saving the data.
                // The approach here is: Either you fetch everything or fail.
                if (!error) {
                    fetchedData[toFetch.key] = receivedData;
                    data[toFetch.key] = receivedData;
                    fetchesRemaining === 0 && callback && callback(error, data);
                }
            });
        }
    });
};

var loadData = function (callback) {
    isLogged(function (err, result) {
        if (!err) {
            user = result;
            return fetchAsync(result ? config.dataArray.concat(config.authenticatedDataArray) : config.dataArray, callback);
        }
        return fetchAsync(config.dataArray, callback);
    });
};

var applyWhere = function (content, property, value, callback) {
    for (var i = 0, len = content.length; i < len; i++) {
        var element = content[i];
        if (element[property] === value) {
            element = callback(element);
        }
    }
};

var applyWhereId = function (content, id, callback) {
    for (var i = 0, len = content.length; i < len; i++) {
        var element = content[i];
        if (element.id === id) {
            return element = callback(element);
        }
    }
};

var whereId = function (content, id) {
    for (var i = 0, len = content.length; i < len; i++) {
        if (content[i].id === id) {
            return content[i];
        }
    }
    return void 0;
};

var where = function (content, property, value) {
    var elems = [];
    for (var i = 0, len = content.length; i < len; i++) {
        if (content[i][property] === value) {
            elems.push(content[i]);
        }
    }
    return elems;
};

var first = function (content, property, value) {
    for (var i = 0, len = content.length; i < len; i++) {
        if (content[i][property] === value) {
            return content[i];
        }
    }
    return void 0;
};

/**
 * The data argument is the data to be sorted (typically an array),
 * the fields argument is an array with all the fields to be sorted, you're
 * allowed to put a single string if you want a basic ascending comparation.
 * Otherwise, you have to use an object with at least the property name (the other
 * params are optional): {property, reverse, parser}
 * The parser is the function to apply before comparating.
 * 
 * Example:
 * var cars = [
 *     {brand: "MERCEDES-BENZ", price: "50000"},
 *     {brand: "ASTON MARTIN", price: "50000"},
 *     {brand: "MERCEDES-BENZ", price: "100000"},
 *     {brand: "ASTON MARTIN", price: "100000"}
 * ];
 * sort(cars, ["brand", {property: "price", parser: parseInt, reverse: true}]);
 */
var sort = (function () {
    var defaultComparator = function (first, second) {
        if (first === second) {
            return 0;
        }
        return first < second ? -1 : 1;
    };
    var getComparator = function (parser, reverse) {
        var comparator = defaultComparator;
        if (parser) {
            comparator = function (first, second) { return defaultComparator(parser(first), parser(second)); };
        }
        if (reverse) {
            return function (first, second) { return -1 * comparator(first, second); };
        }
        return comparator;
    };
    return function (data, fields) {
        var sortSteps = [];
        for (var i = 0, len = fields.length; i < len; i++) {
            var field = fields[i];
            if (typeof field === "string") {
                sortSteps.push({ property: field, comparator: defaultComparator });
            } else {
                sortSteps.push({ property: field.property, comparator: getComparator(field.parser, field.reverse) });
            }
        }
        data.sort(function (first, second) {
            for (var i = 0, len = sortSteps.length; i < len; i++) {
                var result = 0;
                var step = sortSteps[i];
                var property = step.property;
                var comparator = step.comparator;
                result = comparator(first[property], second[property]);
                if (result !== 0) {
                    return result;
                }
            }
            return result;
        });
        return data;
    };
})();

var sortAsc = function (content, property) {
    if (!Array.isArray(content)) {
        return void 0;
    }
    property = property || "order";
    content.sort(function (a, b) {
        var first = isNaN(a[property]) ? a[property] : parseFloat(a[property]);
        var second = isNaN(b[property]) ? b[property] : parseFloat(b[property]);
        if (first < second) {
            return -1;
        }
        if (first > second) {
            return 1;
        }
        return 0;
    });
    return content;
};

var sortDesc = function (content, property) {
    if (!Array.isArray(content)) {
        return void 0;
    }
    property = property || "order";
    content.sort(function (a, b) {
        var first = isNaN(a[property]) ? a[property] : parseFloat(a[property]);
        var second = isNaN(b[property]) ? b[property] : parseFloat(b[property]);
        if (first > second) {
            return -1;
        }
        if (first < second) {
            return 1;
        }
        return 0;
    });
    return content;
};

var iterate = function (content, callback) {
    for (var i = 0; i < content.length; i++) {
        callback(content[i], { i, isLast: i === content.length - 1, isFirst: i === 0, hasNext: i < content.length - 1 });
    }
};

// #region Authentication

var signup = function (data, callback) {
    fetchData(config.authentication.signup, callback, "POST", data);
};

var login = function (data, callback) {
    fetchData(config.authentication.login, function (err, response) {
        if (!err) {
            user = response;
        }
        callback(err, response);
    }, "POST", data);
};

var del = function (data, callback) {
    fetchData(config.authentication.delete, function (err) {
        if (!err) {
            user = void 0;
        }
        callback(err);
    }, "DELETE", data);
};

var update = function (data, callback) {
    fetchData(config.authentication.update, callback, "PUT", data);
};

var logout = function (callback) {
    fetchData(config.authentication.logout, callback);
};

var isLogged = function (callback) {
    fetchData(config.authentication.ping, function (err, response) {
        if (err) {
            console.warn("Failed retrieving authentication status.");
        }
        var logged = !err && response !== "None";
        callback(err, logged ? response : null);
    });
};

var getUser = function () {
    return user;
};

// #endregion

export default {
    where,
    whereId,
    first,
    loadData,
    sortAsc,
    sortDesc,
    iterate,
    sort,
    fetchAsync,
    fetchData,
    signup,
    login,
    update,
    logout,
    getUser,
    applyWhere,
    applyWhereId,
    del
};
