import React, { Component } from "react";
import "./styles.css";
import Keys from "../../Keys";

class Dropdown extends Component {

    constructor(props) {
        super(props);
        this.state = { open: false, active: props.active || 0 };
    }

    _onButtonClick = function () {
        var current = this.state.open;
        this.setState({ open: !current });
    }

    _onClick = function (index, callback) {
        this.setState({ open: false, active: index }, function () {
            callback && callback();
        });
    }

    _fetchItems = function () {
        var items = [];
        for (var i = 0, len = this.props.items.length; i < len; i++) {
            var item = this.props.items[i];
            items.push(<button key={Keys.argsToKey("drop-item-for", item.textContent, i)} className={i === this.state.active ? "Dropdown-active" : ""} onClick={this._onClick.bind(this, i, item.onClick)}>{item.textContent}</button>);
        }
        return items;
    }

    render = function () {
        return (
            <div className="Dropdown">
                <button className={this.state.open ? "Dropdown-button Dropdown-button-pressed" : "Dropdown-button"} onClick={this._onButtonClick.bind(this)}>{this.props.items[this.state.active].textContent + " ▼"}</button>
                {this.props.items && this.state.open && <div className="Dropdown-content">{this._fetchItems.call(this)} </div>}
            </div>
        );
    }
}

export default Dropdown;