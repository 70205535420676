import ReactDOM from "react-dom";
import React from "react";
import PageNotFoundContainer from "./PageNotFoundContainer";

const config = require("./config");

var routes = {};

var isFunction = function (fun) {
    return fun && {}.toString.call(fun) === "[object Function]";
};

var fetchHash = function (hash) {
    if (Array.isArray(hash)) {
        return encodeURI(hash.join(config.delimiter));
    }
    if (typeof hash === "string") {
        return encodeURI(hash);
    }
    return "";
};

var fetchRenderable = function (hash) {
    var renderable = routes[hash] || routes[hash.substring(0, hash.indexOf(config.secondDelimiter))];
    if (renderable) {
        return renderable;
    }
    return void 0;
};

var addRoute = function (hash, renderable) {
    routes["#" + fetchHash(hash)] = renderable;
};

var getElement = function (index) {
    var secondDelimiterIndex = window.location.hash.indexOf(config.secondDelimiter);
    if (secondDelimiterIndex !== -1) {
        return decodeURI(window.location.hash.substring(1, secondDelimiterIndex)).split(config.delimiter)[index];
    }
    return decodeURI(window.location.hash.substring(1)).split(config.delimiter)[index];
};

var getSubElement = function (index) {
    return decodeURI(window.location.hash.substring(window.location.hash.indexOf(config.secondDelimiter) + 1)).split(config.delimiter)[index];
};

var getHash = function () {
    var secondDelimiterIndex = window.location.hash.indexOf(config.secondDelimiter);
    if (secondDelimiterIndex !== -1) {
        return decodeURI(window.location.hash.substring(1, secondDelimiterIndex));
    }
    return decodeURI(window.location.hash.substring(1));
};

var getSubHash = function () {
    return decodeURI(window.location.hash.substring(window.location.hash.indexOf(config.secondDelimiter) + 1));
};

var getFullHash = function () {
    var content = getSubHash();
    var subHash = getSubHash();
    return subHash ? content + config.secondDelimiter + subHash : content;
};

var deleteRoute = function (hash) {
    delete routes["#" + encodeURI(hash)];
};

var renderCurrent = function () {
    if (window.location.hash) {
        var renderable = fetchRenderable(window.location.hash);
        if (isFunction(renderable)) {
            renderable();
        } else {
            ReactDOM.render(renderable || <PageNotFoundContainer title={config.notFoundTitle} intro={config.notFoundIntro} />, document.getElementById(config.rootId)).forceUpdate();
        }
    }
};

var hasHash = function () {
    return !!window.location.hash;
};

window.addEventListener("hashchange", renderCurrent);

var goTo = function (hash, subHash) {
    window.location.hash = subHash ? "#" + fetchHash(hash) + config.secondDelimiter + fetchHash(subHash) : "#" + fetchHash(hash);
};

export default {
    goTo,
    addRoute,
    deleteRoute,
    getElement,
    getSubElement,
    getHash,
    getSubHash,
    getFullHash,
    hasHash,
    renderCurrent
};