const config = require("../config");

module.exports = {
    localStorageLangKey: config.localStorageLangKey,
    defaultLanguage: "en",
    languagePackEndpoint: {
        key: "languagePack",
        endpoint: config.mainUrl + "language_pack/{languagePackId}"
    },
    languages: "languages",
    keys: "keys",
    fields: "fields",
    languagePackProperty: "language_pack",
    valueProperty: "value",
    keyProperty: "key"
};