import React from "react";
import HashRouter from "../HashRouter";
import ListContainer from "../Components/ListContainer";
import TabContainer from "../Components/TabContainer";
import MediaContainer from "../Components/MediaContainer";
import DetailsContainer from "../Components/DetailsContainer";
import TabbedListContainer from "../Components/TabbedListContainer";
import Data from "../Data";
import NavigationContainer from "../Components/NavigationContainer";
import WeatherWidget from "../Components/WeatherWidget";
import Keys from "../Keys";
import Strings from "../Strings";
import Utils from "../utils";
import Toast from "../Components/Toast/Toast";
import DeleteProfileContainer from "../Components/DeleteProfileContainer";
import UpdateProfileContainer from "../Components/UpdateProfileContainer";
import SignupContainer from "../Components/SignupContainer";

const config = require("../config");
var refresh;

var dataToForecast = function (receivedData) {
    var array = [];
    receivedData.forecast.forecast.forEach(function (element) {
        var temperature = element[config.temperatureProperty];
        array.push({
            date: new Date(element.instant),
            temperature: Math.round(temperature.value - 273.15),
            icon: temperature.icon
        });
    });
    array.sort(function (first, second) { return first.date - second.date })
    return array;
};

/**
 * All custom routes here
 */
var addTracks = function (data) {
    data.tracks.forEach(function (item) {
        var onPrevious = Data.first(data.tracks, "order", item.order - 1);
        var onNext = Data.first(data.tracks, "order", item.order + 1);
        var buttons = [];
        Data.where(data.routes, "track", item.id).forEach(function (route) {
            buttons.push({ src: config.icons.kml, href: config.baseBlob + route.kml + ".kml" });
            buttons.push({ src: config.icons.gpx, href: config.baseBlob + route.kml + ".gpx" });
        });
        var widget;
        if (item.forecast) {
            var coordinate = Data.whereId(data.forecastPoints, item.forecast);
            widget = {
                label: Strings.get("weather"),
                widget: <WeatherWidget
                    key={Keys.argsToKey("weather-widget", coordinate.latitude, coordinate.longitude)}
                    endpoints={[
                        {
                            endpoint: config.weather.apiEndpoint.replace("{lat}", coordinate.latitude).replace("{lng}", coordinate.longitude),
                            key: "forecast"
                        }
                    ]}
                    iconBasePath={config.weather.iconsPath}
                    dataToForecast={dataToForecast}
                />
            };
        }
        var items = [
            {
                textContent: Strings.get("description").toUpperCase(),
                renderable: <DetailsContainer
                    title={item.order + ". " + item.description}
                    widget={widget}
                    banner={{
                        label: Strings.get("elevationProfile"),
                        src: config.baseBlob + item.hypsometry + ".svg",
                        handlerText: Strings.get("seeOnMap"),
                        handler: HashRouter.goTo.bind(null, [config.navUrl, item.path, item.id])
                    }}
                    items={[
                        {
                            label: Strings.get("description"),
                            textContent: Strings.get(item.information)
                        },
                        {
                            label: Strings.get("difficultyLevel"),
                            textContent: Utils.getDifficultyLevel(data, item.difficulty_level)
                        }
                    ]}
                    buttons={buttons}
                    buttonsLabel={Strings.get("trackFiles")}
                    onLeftClick={onPrevious && HashRouter.goTo.bind(null, [config.pathsUrl, item.path, onPrevious.id], ["0"])}
                    onRightClick={onNext && HashRouter.goTo.bind(null, [config.pathsUrl, item.path, onNext.id], ["0"])}
                />
            },
            {
                textContent: Strings.get("services").toUpperCase(),
                renderable: <TabbedListContainer
                    active={HashRouter.getElement(3)}
                    categories={[Strings.get("lodging"), Strings.get("feeding"), Strings.get("miscellaneous")]}
                    sorts={[
                        {
                            name: Strings.get("route"),
                            sort: function (a, b) {
                                var orderA = parseInt(a.order, 10);
                                var orderB = parseInt(b.order, 10);
                                if (!orderA) { return 1 };
                                if (!orderB) { return -1 };
                                return orderA - orderB;
                            }
                        }]}
                    title={item.order + ". " + item.description}
                    items={fetchTabbedListItems(data, item.id)}
                    onLeftClick={onPrevious && HashRouter.goTo.bind(null, [config.pathsUrl, item.path, onPrevious.id], ["1"])}
                    onRightClick={onNext && HashRouter.goTo.bind(null, [config.pathsUrl, item.path, onNext.id], ["1"])}
                />
            }
        ];
        var medias = [];
        Data.iterate(Data.sortAsc(Data.where(data.trackMedias, "track", item.id)), function (trackMedia) {
            medias.push({ src: config.baseBlob + Data.whereId(data.medias, trackMedia.media).description, description: Strings.get(trackMedia.notes) });
        });
        medias.length && items.push({
            textContent: Strings.get("pictures").toUpperCase(),
            renderable:
                <MediaContainer
                    key={Keys.argsToKey("media-container", item.id, item.description)}
                    title={item.order + ". " + item.description}
                    items={medias}
                    onLeftClick={onPrevious && HashRouter.goTo.bind(null, [config.pathsUrl, item.path, onPrevious.id], ["2"])}
                    onRightClick={onNext && HashRouter.goTo.bind(null, [config.pathsUrl, item.path, onNext.id], ["2"])}
                />
        });
        HashRouter.addRoute([config.pathsUrl, item.path, item.id],
            <TabContainer
                items={items}
                onReturn={HashRouter.goTo.bind(null, [config.pathsUrl, item.path])}
                onReturnText={Strings.get("stages").toUpperCase()}
            />
        );
    });
};

var renderTrackDrawables = function (data, pathId) {
    return function (receivedData) {
        var polylines = [];
        var markers = [];
        Data.iterate(Data.sortAsc(Data.where(data.tracks, "path", pathId), "order"), function (track, optionsA) {
            var polyline = [];
            Data.where(data.routes, "track", track.id).forEach(function (route) {
                Data.iterate(Data.sortAsc(Data.where(receivedData.routeCoordinates, "route", route.id), "order"), function (routeCoordinate, optionsB) {
                    (optionsB.i % 5 === 0 || optionsB.isLast || optionsB.isFirst) && polyline.push({ lat: routeCoordinate.latitude, lng: routeCoordinate.longitude });
                    optionsA.isFirst && optionsB.isFirst && markers.push({ type: "play", lat: routeCoordinate.latitude, lng: routeCoordinate.longitude });
                    optionsA.isLast && optionsB.isLast && markers.push({ type: "red_flag", lat: routeCoordinate.latitude, lng: routeCoordinate.longitude });
                });
            });
            if (polyline.length > 0) {
                polyline.popup = [track.order + ". " + track.description, track.distance + " Km", Utils.getDifficultyLevel(data, track.difficulty_level)];
                // @TODO: REMOVE BIT SUPPORT, ALLOW ONLY BOOLEAN SUPPORT
                var isActive;
                if (Array.isArray(track.active.data)) { // Bit array
                    isActive = track.active.data[0];
                } else { // Boolean
                    isActive = track.active;
                }
                if (isActive) {
                    polyline.color = config.polylineColors[optionsA.i % config.polylineColors.length];
                    polyline.handler = HashRouter.goTo.bind(null, [config.navUrl, pathId, track.id]);
                } else {
                    polyline.color = config.deactivatedColor;
                    polyline.popup.push(config.soonAvailableText);
                }
                polylines.push(polyline);
            }
        });
        return { polylines, markers };
    }
};

var addMapTracks = function (data) {
    data.paths.forEach(function (path) {
        HashRouter.addRoute([config.navUrl, path.id],
            <NavigationContainer
                key={Keys.argsToKey(config.navUrl, path.id)}
                zoom={8}
                dataToDrawables={renderTrackDrawables(data, path.id)}
                endpoints={[{ endpoint: config.routeCoordinates.apiEndPoint, key: "routeCoordinates", cache: true }]}
            />);
    });
};

var renderTrackDetailsDrawables = function (data, pathId, trackId) {
    return function (receivedData) {
        var polylines = [];
        var markers = Utils.getTrackPointsMarkers(data, trackId);
        Data.where(data.routes, "track", trackId).forEach(function (route) {
            var polyline = [];
            Data.iterate(Data.sortAsc(Data.where(receivedData.routeCoordinates, "route", route.id), "order"), function (routeCoordinate, options) {
                polyline.push({ lat: routeCoordinate.latitude, lng: routeCoordinate.longitude });
                options.isFirst && markers.push({ type: "play", lat: routeCoordinate.latitude, lng: routeCoordinate.longitude });
                options.isLast && markers.push({ type: "red_flag", lat: routeCoordinate.latitude, lng: routeCoordinate.longitude });
            });
            if (polyline.length > 0) {
                polyline.handler = HashRouter.goTo.bind(null, [config.pathsUrl, pathId, trackId])
                polyline.color = config.trackDetailsPolylineColor;
                polylines.push(polyline);
            }
        });
        return { polylines, markers };
    };
};

var addMapTrackDetails = function (data) {
    data.tracks.forEach(function (track) {
        HashRouter.addRoute([config.navUrl, track.path, track.id],
            <NavigationContainer
                key={Keys.argsToKey(config.navUrl, track.path, track.id)}
                zoom={11}
                dataToDrawables={renderTrackDetailsDrawables(data, track.path, track.id)}
                endpoints={[{ endpoint: config.routeCoordinates.apiEndPoint, key: "routeCoordinates", cache: true }]}
            />
        );
    });
};

var fetchTabbedListItems = function (data, trackId) {
    var items = [];
    var trackPoints = Data.where(data.trackPlaces, "track", trackId);
    trackPoints.forEach(function (trackPoint) {
        var point = Data.whereId(data.places, trackPoint.place);
        var local = Data.whereId(data.localizations, point.localization);
        var item = { order: local.order, handler: HashRouter.goTo.bind(null, [config.pointsUrl, point.id]) };
        var contact = Utils.getMostImportantContact(Data.where(data.contacts, "place", point.id));
        item.textLines = [local.description, point.description, contact || ""];
        if (Data.first(data.accomodations, "place", point.id)) {
            item.tabIndex = 0;
            item.logo = config.icons.accomodation
        } else if (Data.first(data.foods, "place", point.id)) {
            item.tabIndex = 1;
            item.logo = config.icons.food
        } else {
            item.tabIndex = 2;
        }
        items.push(item);
    });
    return items;
};

var addPaths = function (data) {
    data.paths.forEach(function (path) {
        var items = [];
        Data.sortAsc(Data.where(data.tracks, "path", path.id), "order").forEach(function (track) {
            var image = Data.whereId(data.medias, track.icon);
            image && (image = image.description);
            var nAccomodations = 0;
            Data.where(data.trackPlaces, "track", track.id).forEach(function (item) {
                Data.first(data.accomodations, "place", item.place) && nAccomodations++;
            });
            items.push({
                textLines: [track.order + ". " + track.description, "" + track.distance + " Km (" + nAccomodations + " " + Strings.get("lodges") + ")", Utils.getDifficultyLevel(data, track.difficulty_level)],
                handler: HashRouter.goTo.bind(null, [config.pathsUrl, track.path, track.id]),
                image: image ? config.baseBlob + Data.whereId(data.medias, track.icon).description : void 0,
                logo: config.icons.tracks
            });
        });
        var media = Data.whereId(data.medias, path.background);
        media && (media = config.baseBlob + media.description);
        HashRouter.addRoute([config.pathsUrl, path.id],
            <ListContainer title={path.description}
                intro={Strings.get("stages")}
                items={items}
                bgImage={media}
            />
        );
    });
};

var ratingHandler = function (pointId, rating) {
    return function (value, fallback) {
        var toDelete = value === void 0;
        Data.fetchData("plc_rating/" + pointId, function (err) {
            if (err) {
                Toast.show(Strings.get("ratingError"), { type: "error" });
                return fallback();
            }
            Toast.show(Strings.get("afterRating"), { type: "success" });
            refresh();
        }, toDelete ? "DELETE" : "POST", toDelete ? void 0 : { rating: value });
    };
};

var addPoints = function (data) {
    data.places.forEach(function (point) {
        var totalRating = 0;
        var ratings = Data.where(data.ratings, "place", point.id);
        ratings.forEach(function (rating) {
            totalRating += rating.rating;
        });
        var buttons = [];
        point.website && buttons.push({ src: config.icons.website, href: point.website });
        point.facebook && buttons.push({ src: config.icons.facebook, href: point.facebook });
        ratings.length > 1 && (totalRating /= ratings.length);
        var user = Data.getUser();
        var inputRating;
        if (user) {
            var value = Data.first(Data.where(data.ratings, "user", Data.getUser().id), "place", point.id);
            inputRating = {
                label: Strings.get("usersRating"),
                key: Keys.argsToKey("-input-rating-for", point.id),
                value: value ? value.rating : void 0,
                onChange: ratingHandler(point.id, value)
            };
        }
        var items = [];
        items.push({
            label: Strings.get("detailsLocation"),
            textContent: Data.whereId(data.localizations, point.localization).description
        });
        point.information && items.push({ label: Strings.get("info"), textContent: Utils.getPlaceInfo(data, point.id) });
        point.address && items.push({ label: Strings.get("address"), textContent: point.address });
        var contacts = Data.where(data.contacts, "place", point.id);
        var textContent = [];
        for (var i = 0, len = contacts.length; i < len; i++) {
            var contact = contacts[i];
            textContent.push(contact.description);
            textContent.push(" (" + Data.whereId(data.contactTypes, contact.type).description + ")");
            contact.notes && (textContent.push(" - " + contact.notes));
            i < len - 1 && (textContent.push(<br />));
        }
        contacts.length > 0 && items.push({ label: Strings.get("contacts"), textContent });
        var specs = Data.first(data.accomodations, "place", point.id)
        if (specs) {
            specs.price && (items.push({ label: Strings.get("price"), textContent: specs.price + " " + config.priceUnit }));
            specs.accomodation_type && (items.push({ label: Strings.get("type"), textContent: Utils.getAccomodationType(data, specs.accomodation_type) }));
            specs.accomodation_management_type && (items.push({ label: Strings.get("management"), textContent: Utils.getManagementType(data, specs.accomodation_management_type) }));
        } else {
            specs = Data.first(data.foods, "place", point.id)
            if (specs) {
                items.push({ label: Strings.get("type"), textContent: Utils.getPlaceType(data, point.place_type) });
            }
        }
        HashRouter.addRoute([config.pointsUrl, point.id],
            <DetailsContainer
                title={point.description}
                logo={Utils.getLogo(data, point.place_type)}
                items={items}
                buttons={buttons}
                buttonsLabel={Strings.get("pointLinks")}
                inputRating={inputRating}
                rating={ratings.length > 0 ? { value: totalRating, raters: ratings.length, label: Strings.get("totalRating") } : void 0}
            />
        );
    });
};

var addUpdate = function (data) {
    var user = data.user[0];
    HashRouter.addRoute([config.updateUrl],
        <UpdateProfileContainer
            title={Strings.get("updateProfile")}
            email={user.email}
            submitValue={Strings.get("update")}
            newPasswordLabel={Strings.get("newPassword")}
            onUpdate={function (err) {
                if (err) {
                    return Toast.show(err, { type: "error" });
                }
                refresh();
                Toast.show(Strings.get("afterUpdate"), { type: "success" });
                HashRouter.goTo("profile");
            }} >
            {/* <FormSelect id="language" label={Strings.get("LANGUAGE")} options={Utils.getLanguageOptions(data)} value={user.language} /> */}
        </UpdateProfileContainer>
        /*
    <FormField id="firstName" label={Strings.get("firstName")} required={true} value={user.firstName} />
    <FormField id="lastName" label={Strings.get("lastName")} required={true} value={user.lastName} />
    <FormField id="phone" label={Strings.get("phone")} required={false} />
    <FormRadios id="male" label={Strings.get("gender")} radios={[{ label: Strings.get("maleGenderOption"), value: true }, { label: Strings.get("femaleGenderOption"), value: false }]} value={user.male} required={false} />
    <FormSelect id="nationalityId" label={Strings.get("nationality")} options={utils.getNationalityOptions(data)} value={user.nationality} required={true} />
    <FormDate id="birthDate" format="YYYY-MM-DD" label={Strings.get("birthDate")} value={new Date(user.birthDate)} required={true} />
    */
    );
};

var addDelete = function () {
    HashRouter.addRoute([config.deleteUrl],
        <DeleteProfileContainer
            title={Strings.get("deleteProfileTitle")}
            submitValue={Strings.get("deleteProfile")}
            warningMessage={Strings.get("deleteWarningMessage")}
            onDelete={function (err) {
                if (err) {
                    return Toast.show(err, { type: "error" });
                }
                refresh();
                HashRouter.goTo(config.homeUrl);
                Toast.show(Strings.get("afterDelete"), { type: "success" });
            }}
        />
    );
};

var addSignup = function (data) {
    HashRouter.addRoute([config.signupUrl],
        <SignupContainer
            title={Strings.get("navSignup")}
            submitValue={Strings.get("navSignup")}
            text={Strings.get("signupWelcomeText")}
            loginRedirect={{ text: Strings.get("hasAccountLoginNow"), href: "#" + config.loginUrl }}
            email={true}
            usernameName="description"
            recaptchaSiteKey={config.recaptchaSiteKey}
            loginRedir
            onSignup={function (err) {
                if (err) {
                    return Toast.show(err, { type: "error" });
                }
                HashRouter.goTo(config.loginUrl);
                Toast.show(Strings.get("afterSignup"), { type: "success" });
            }}
        />
        // <FormField id="firstName" label={Strings.get("firstName")} required={true} />
        // <FormField id="lastName" label={Strings.get("lastName")} required={true} />
        // <FormField id="phone" label={Strings.get("phone")} required={false} />
        // <FormRadios id="male" label={Strings.get("gender")} radios={[{ label: Strings.get("maleGenderOption"), value: true }, { label: Strings.get("femaleGenderOption"), value: false }]} required={false} />
        // <FormSelect id="nationalityId" label={Strings.get("nationality")} options={utils.getNationalityOptions(data)} required={true} />
        // <FormDate id="birthDate" format="YYYY-MM-DD" label={Strings.get("birthDate")} required={true} />
    );
};

var setCustomRoutes = function (data) {
    addTracks(data);
    addPaths(data);
    addPoints(data);
    addMapTracks(data);
    addMapTrackDetails(data);
    if (Data.getUser()) {
        addUpdate(data);
        addDelete(data);
    } else {
        /**
         * Signup page
         */
        addSignup(data);
    }
};

var setRoutesFromItems = function (items) {
    for (var key in items) {
        HashRouter.addRoute(key, items[key].renderable);
    }
};

export default function (options) {
    options && options.items && setRoutesFromItems(options.items);
    options && options.data && setCustomRoutes(options.data);
    refresh = function () {
        Data.loadData(function (err, data) {
            if (err) {
                return Toast.show("Error", { type: "error" });
            }
            options.init(data);
        });
    };
};
