import React, { Component } from "react";
import "./styles.css";
import Keys from "../../Keys";
import Header from "../Header";
import Container from "../Container";

class MediaContainer extends Component {

    constructor(props) {
        super(props);
        this.state = { index: 0 };
    }
    _fetchImages = function () {
        var images = [];
        for (var i = 0, len = this.props.items.length; i < len; i++) {
            var image = this.props.items[i];
            images.push(<img key={Keys.argsToKey("media-img-for", image.src, image.description, i)} className={this.state.index === i ? "MediaContainer-slideItem" : "MediaContainer-slideItemHidden"} src={image.src} alt={image.description || "Caroussel image item"} />);
        }
        return images;
    }

    _fetchDescription = function () {
        return this.props.items[this.state.index].description ? <p>{this.props.items[this.state.index].description}</p> : void 0;
    }

    _fetchNextPrevButtons = function () {
        var previousEnabled = this.state.index > 0;
        var nextEnabled = this.state.index < this.props.items.length - 1;
        var buttons = [];
        buttons.push(<div key={Keys.argsToKey("previous-media-image")} className={previousEnabled ? "MediaContainer-navigator" : "MediaContainer-navigatorDisabled"} onClick={previousEnabled ? this._sumIndex.bind(this, -1) : void 0}>&#10094;</div>);
        buttons.push(<div key={Keys.argsToKey("next-media-image")} className={nextEnabled ? "MediaContainer-navigator" : "MediaContainer-navigatorDisabled"} onClick={nextEnabled ? this._sumIndex.bind(this, 1) : void 0}>&#10095;</div>);
        return buttons;
    }

    _sumIndex = function (value) {
        var currentIndex = this.state.index;
        this._goToIndex(currentIndex + value);
    }

    _goToIndex = function (value) {
        this.setState({ index: value });
    }

    render() {
        return (
            <Container textAlignCenter={true}>
                <Header title={this.props.title} logo={this.props.logo} type={Header.Type.REGULAR}
                    onLeftClick={this.props.onLeftClick} leftClickText={this.props.leftClickText}
                    onRightClick={this.props.onRightClick} rightClickText={this.props.rightClickText} />
                {this.props.intro ? <p className="MediaContainer-intro">{this.props.intro}</p> : ""}
                <div className="MediaContainer-vertically-centered">
                    <div className="MediaContainer-slideshow">
                        {this._fetchImages.call(this)}
                        {this.props.items.length > 1 && this._fetchNextPrevButtons.call(this)}
                    </div>
                    {this._fetchDescription.call(this)}
                </div>
            </Container>
        );
    }
}

export default MediaContainer;