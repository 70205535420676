const config = require("../config");

module.exports = {
    apiEndPoint: config.mainUrl + "api/",
    treatAsErrorWhen: {
        status: [
            /^(4[0-9]{2})$/, // 4XX status codes
            /^(5[0-9]{2})$/ // 5XX status codes
        ],
        response: []
    },
    authentication: {
        ping: config.mainUrl + "authentication_ping/",
        login: config.authenticationEndpoints.login,
        signup: config.authenticationEndpoints.signup,
        logout: config.authenticationEndpoints.logout,
        update: config.authenticationEndpoints.update,
        delete: config.authenticationEndpoints.delete
    },
    dataArray: [
        { endpoint: "pth_path", key: "paths", cache: true },
        { endpoint: "pth_track_place", key: "trackPlaces", cache: true },
        { endpoint: "pth_track", key: "tracks", cache: true },
        { endpoint: "plc_point", key: "points", cache: true },
        { endpoint: "plc_localization", key: "localizations", cache: true },
        { endpoint: "plc_place", key: "places", cache: true },
        { endpoint: "plc_contact", key: "contacts", cache: true },
        { endpoint: "plc_contact_type", key: "contactTypes", cache: true },
        { endpoint: "plc_accomodation", key: "accomodations", cache: true },
        { endpoint: "plc_accomodation_type", key: "accomodationTypes", cache: true },
        { endpoint: "plc_accomodation_management_type", key: "accomodationManagementTypes", cache: true },
        { endpoint: "plc_food", key: "foods", cache: true },
        { endpoint: "plc_rating", key: "ratings", cache: false }, // Can't cache because it should be refreshed if authentication changes
        { endpoint: "plc_place_type", key: "placeTypes", cache: true },
        { endpoint: "bck_media", key: "medias", cache: true },
        { endpoint: "pth_track_media", key: "trackMedias", cache: true },
        { endpoint: "pth_difficulty_level", key: "difficulties", cache: true },
        { endpoint: "pth_route", key: "routes", cache: true },
        { endpoint: "frc_point", key: "forecastPoints", cache: true },
        { endpoint: "lng_language", key: "languages", cache: true },
        { endpoint: "lng_language_pack", key: "languagePacks", cache: true },
        { endpoint: "lng_key", key: "keys", cache: true },
        { endpoint: "pth_track_point", key: "trackPoints", cache: true },
        { endpoint: "bck_nationality", key: "nationalities", cache: true }
    ],
    authenticatedDataArray: [
        { endpoint: "user", key: "user", cache: false }
    ]
};