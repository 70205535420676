import React, { Component } from "react";
import Form from "../Form";
import FormField from "../Form/FormField";
import Header from "../Header";
import Container from "../Container";
import Data from "../../Data";
import FormRecaptcha from "../Form/FormRecaptcha";

class UpdateProfileContainer extends Component {

    _update = function (data) {
        this.props.dataToUpdate && (data = this.props.dataToUpdate(data));
        Data.update(data, this.props.onUpdate);
    }

    _getNewPasswordId = function () {
        if (!this.props.passwordName) {
            return "newPassword";
        }
        var passwordName = this.props.passwordName;
        return "new" + passwordName.charAt(0).toUpperCase() + passwordName.slice(1);
    }

    render = function () {
        return (
            <Container textAlignCenter={true}>
                <Header title={this.props.title} logo={this.props.logo} type={Header.Type.REGULAR} />
                <Form onSubmit={this._update.bind(this)} submitValue={this.props.submitValue || this.props.title}>
                    {this.props.email && <FormField id={this.props.emailName || "email"} label={this.props.emailLabel || "Email"} value={this.props.email} required={true} />}
                    {this.props.username && <FormField id={this.props.usernameName || "username"} label={this.props.usernameLabel || "Username"} required={true} />}
                    {this.props.children}
                    <FormField id={this.props.passwordName || "password"} label={this.props.passwordLabel || "Password"} type="password" required={true} />
                    <FormField id={this._getNewPasswordId()} label={this.props.newPasswordLabel || "New Password"} type="password" required={false} />
                    {this.props.recaptchaSiteKey && <FormRecaptcha id={this.props.recaptchaId || "recaptcha"} sitekey={this.props.recaptchaSiteKey} />}
                </Form>
            </Container>
        );
    }
}

export default UpdateProfileContainer;