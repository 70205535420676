import React, { Component } from "react";
import "./styles.css";

class Form extends Component {

    constructor(props) {
        super(props);
        var fieldData = {};
        React.Children.forEach(props.children, function (child) {
            child && child.props.value && (fieldData[child.props.id] = child.props.value);
        });
        this.state = { fieldData };
    }

    _setFieldData(key, data, callback) {
        var fieldData = this.state.fieldData;
        fieldData[key] = data;
        this.setState({ fieldData }, callback);
    }

    _onSubmit = function (event) {
        // Ignore page reload
        event.preventDefault();
        event.stopPropagation();
        this.props.onSubmit(this.state.fieldData);
    }

    /**
     * We need to add the "onChange" prop to the children in order to update the whole
     * form's field data. The reason why it's done this way it's because it's not
     * possible to access the children's state from the parent, so the strategy
     * used is to update the form's data every time a child's input is changed.
     * To accomplish that, we send the method "_setFieldData" to the children.
     * https://stackoverflow.com/questions/32370994/how-to-pass-props-to-this-props-children
     */
    _renderChildren = function () {
        var children = this.props.children;
        return React.Children.map(children, function (child) {
            if (!child) {
                return null;
            }
            return React.cloneElement(child, { onChange: this._setFieldData.bind(this) });
        }.bind(this));
    }

    render = function () {
        return (
            <form onSubmit={this._onSubmit.bind(this)}>
                {this._renderChildren()}
                <input className="Form-submitButton" type="submit" value={this.props.submitValue} />
            </form>
        );
    }
}

export default Form;