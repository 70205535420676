import React, { Component } from "react";
import "./styles.css";
import ListItem from "../ListItem";
import Dropdown from "../Dropdown";
import DefaultLogo from "./default.png";
import HashRouter from "../../HashRouter";
import Keys from "../../Keys";
import Header from "../Header";
import Container from "../Container";

class TabbedListContainer extends Component {

    constructor(props) {
        super(props);
        this.state = { activeSort: this.props.sorts ? 0 : void 0 };
    }

    _getActive = function () {
        return parseInt(HashRouter.getSubElement(1), 10) || 0;
    }

    _fetchItems = function () {
        if (!this.props.items) {
            return [];
        }
        var elements = [];
        // Props are immutable so it's needed to create a new array and do the changes to it
        var items = this.state.activeSort === void 0 ? this.props.items : [].concat(this.props.items).sort(this.props.sorts[this.state.activeSort].sort);
        for (var i = 0, len = items.length; i < len; i++) {
            var item = items[i];
            if (item.tabIndex === this._getActive()) {
                elements.push(<ListItem key={Keys.argsToKey("tabbed-li-for", (item.textContent || item.textLines[0]), i)} priority={item.priority} logo={item.logo || DefaultLogo} image={item.image} handler={item.handler} textContent={item.textContent} textLines={item.textLines} />);
            }
        }
        return elements;
    }

    _changeTabIndex = function (index) {
        HashRouter.goTo(HashRouter.getHash(), [HashRouter.getSubElement(0), index]);
        this.forceUpdate();
    }

    _changeSort = function (index) {
        this.setState({ activeSort: index });
    }

    _fetchCategories = function () {
        var elements = [];
        for (var i = 0, len = this.props.categories.length; i < len; i++) {
            var category = this.props.categories[i];
            elements.push({ textContent: category, onClick: this._changeTabIndex.bind(this, i) });
        }
        return <Dropdown key={Keys.argsToKey("cat-drop-for", this.props.title, i)} items={elements} active={this._getActive.call(this)} />;
    }

    _fetchSorts = function () {
        if (!this.props.sorts || this.props.sorts.length < 2) {
            return [];
        }
        var elements = [];
        for (var i = 0, len = this.props.sorts.length; i < len; i++) {
            var sort = this.props.sorts[i];
            elements.push({ textContent: sort.name, onClick: this._changeSort.bind(this, i) });
        }
        return <Dropdown key={Keys.argsToKey("sort-drop-for", this.props.title, i)} items={elements} />;
    }

    _fetchTab = function () {
        var elements = [];
        this.props.categories && (elements.push(this._fetchCategories()));
        this.props.sorts && (elements.push(this._fetchSorts()));
        return elements;
    }

    render = function () {
        return (
            <Container textAlignCenter={true}>
                <Header title={this.props.title} logo={this.props.logo} type={Header.Type.REGULAR}
                    onLeftClick={this.props.onLeftClick} leftClickText={this.props.leftClickText}
                    onRightClick={this.props.onRightClick} rightClickText={this.props.rightClickText} />
                {this.props.intro && <p className="TabbedListContainer-intro">{this.props.intro}</p>}
                {this._fetchTab.call(this)}
                <div className="TabbedListContainer-items">
                    {this._fetchItems.call(this)}
                </div>
            </Container>
        );
    }
}

export default TabbedListContainer;