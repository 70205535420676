var hue2rgb = function hue2rgb(p, q, t) {
    t < 0 && t++;
    t > 1 && t--;
    if (t < 1 / 6) {
        return p + (q - p) * 6 * t;
    }
    if (t < 1 / 2) {
        return q;
    }
    if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6;
    }
    return p;
};

var preFetchColor = function (hue, saturation, lightness) {
    hue /= 360;
    saturation /= 100;
    lightness /= 100;
    var red, green, blue;
    if (saturation === 0) {
        red = green = blue = lightness; // achromatic
    } else {
        var q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
        var p = 2 * lightness - q;
        red = hue2rgb(p, q, hue + 1 / 3);
        green = hue2rgb(p, q, hue);
        blue = hue2rgb(p, q, hue - 1 / 3);
    }
    return { red, green, blue };
};

var hsl2rgb = function (hue, saturation, lightness) {
    var preFetchedColor = preFetchColor(hue, saturation, lightness);
    return {
        red: Math.round(preFetchedColor.red * 255),
        green: Math.round(preFetchedColor.green * 255),
        blue: Math.round(preFetchedColor.blue * 255)
    };
};

var hsl2hex = (function () {
    var toHex = function (x) {
        var hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };
    return function (hue, saturation, lightness) {
        var preFetchedColor = preFetchColor(hue, saturation, lightness);
        return "#" + toHex(preFetchedColor.red) + toHex(preFetchedColor.green) + toHex(preFetchedColor.blue);
    };
})();

module.exports.hsl2hex = hsl2hex;
module.exports.hsl2rgb = hsl2rgb;