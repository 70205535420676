// https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
var isFunction = function (object) {
    return object && {}.toString.call(object) === "[object Function]";
};

/**
 * Disables access to the React Dev Tools Extension, in order to disallow non-developer
 * having access to components states and structure.
 * This var isn't "deletable" so we have to change all its properties for it to be
 * unable to communicate with the dev tools.
 * Extension: https://github.com/facebook/react-devtools
 * Solution based on: https://github.com/facebook/react-devtools/issues/191
 */
export default function () {
    // If this global object exists
    if (window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
        // Look up every property
        for (var key in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
            // If it's a function, change it to an empty function, other wise simply put null to it
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = isFunction(window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key]) ? function () { } : null;
        }
    }
};