import React, { Component } from "react";
import "./styles.css";
import Keys from "../../Keys";
import Header from "../Header";
import Container from "../Container";
import InputRating from "../InputRating";

class DetailsContainer extends Component {

    _fetchFields = function () {
        var elements = [];
        for (var i = 0, len = this.props.items.length; i < len; i++) {
            var item = this.props.items[i];
            elements.push(<h3 className="DetailsContainer-label" key={Keys.argsToKey("label-for", this.props.title, item.label, i)}>{item.label}</h3>);
            elements.push(<div className="DetailsContainer-value" key={Keys.argsToKey("textContent-for", this.props.title, item.label, i)}>{item.textContent}</div>);
        }
        return elements;
    }

    _fetchButtonRow = function () {
        var buttons = [];
        for (var i = 0, len = this.props.buttons.length; i < len; i++) {
            var button = this.props.buttons[i];
            if (button.src) {
                buttons.push(<a className="DetailsContainer-buttonLink" target="_blank" rel="noopener noreferrer" onClick={button.onClick} key={Keys.argsToKey("a", button.href, this.props.title)} href={button.href}><img className={"DetailsContainer-buttonImage"} key={Keys.argsToKey("img", button.href)} alt={button.alt || "Button element number " + i + " for the button row"} src={button.src} /></a>);
            } else {
                buttons.push(<button className="DetailsContainer-button" onClick={button.onClick} key={Keys.argsToKey("button", button.href, this.props.title)} href={button.href}>{button.value || "Ok"}</button>);
            }
        }
        return <div className="DetailsContainer-buttonRow">{buttons}</div>;
    }

    _fetchBanner = function () {
        return <div className="DetailsContainer-banner">
            <h3 className="DetailsContainer-label">{this.props.banner.label}</h3>
            <img className={this.props.banner.handler && !this.props.banner.handlerText ? "DetailsContainer-clickableBannerImage" : "DetailsContainer-bannerImage"} src={this.props.banner.src} alt="Banner" onClick={this.props.banner.handlerText ? void 0 : this.props.banner.handler} />
            {this.props.banner.handler && this.props.banner.handlerText && <button className="DetailsContainer-bannerHandler" onClick={this.props.banner.handler}>{this.props.banner.handlerText}</button>}
        </div>;
    }

    _fetchInputRating = function () {
        return [
            <h3 key={Keys.argsToKey("label-for-rating-input", this.props.inputRating.key)} className="DetailsContainer-label">{this.props.inputRating.label}</h3>,
            <InputRating key={this.props.inputRating.key} value={this.props.inputRating.value} onChange={this.props.inputRating.onChange} />
        ];
    }

    _getRoundedRating = function () {
        return Math.round(10 * this.props.rating.value) / 10;
    }

    _fetchRating = function () {
        return [
            <h3 key={Keys.argsToKey("label-for-rating", this.props.title)} className="DetailsContainer-label">{this.props.rating.label}</h3>,
            <div className="DetailsContainer-value" key={Keys.argsToKey("value-for-rating", this.props.title)}>{this._getRoundedRating() + "★ (" + this.props.rating.raters + ")"}</div>,
            <div key={Keys.argsToKey("star-rating", this.props.title)} className="DetailsContainer-ratingContainer"><span className="DetailsContainer-rating" style={{ width: ((this.props.rating.value * 100) / 5) + "%" }}></span></div>
        ]
    }

    render = function () {
        return (
            <Container>
                <Header title={this.props.title} logo={this.props.logo} type={Header.Type.REGULAR}
                    onLeftClick={this.props.onLeftClick} leftClickText={this.props.leftClickText}
                    onRightClick={this.props.onRightClick} rightClickText={this.props.rightClickText} />
                {this.props.inputRating && this._fetchInputRating()}
                {/* {this.props.ratingValue !== void 0 && this._fetchInputRating()} */}
                {this.props.rating && this._fetchRating()}
                {this._fetchFields.call(this)}
                {this.props.widget && this.props.widget.label && <h3 className="DetailsContainer-label">{this.props.widget.label}</h3>}
                {this.props.widget && this.props.widget.widget}
                {this.props.banner && this._fetchBanner()}
                {this.props.buttons && this.props.buttons.length > 0 && <h3 className="DetailsContainer-label">{this.props.buttonsLabel}</h3>}
                {this.props.buttons && this.props.buttons.length > 0 && this._fetchButtonRow()}
            </Container>
        );
    }
}

export default DetailsContainer;
