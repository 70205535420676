import React, { Component } from "react";
import "./Toast.css";

const toastId = "toast";

class Toast extends Component {

    static mergeOptions = (function () {
        var defaultOptions = {
            delay: 3000,
            type: "neutral",
            align: "center",
            bold: true
        };
        return function (options) {
            if (!options) {
                return defaultOptions;
            }
            for (var option in defaultOptions) {
                if (!options.hasOwnProperty(option)) {
                    options[option] = defaultOptions[option];
                }
            }
            return options;
        };
    })()

    static show = (function () {
        var timeout;
        return function (text, options, callback) {
            options = Toast.mergeOptions(options);
            var toast = document.getElementById(toastId);
            toast.textContent = text;
            toast.className = "show " + options.type + " " + options.align;
            if (options.bold) {
                toast.className += " bold";
            }
            if (timeout) { // Already showing
                clearTimeout(timeout);
            }
            timeout = setTimeout(Toast.hide.bind(null, callback), options.delay);
            toast.onclick = function (timeout, callback) {
                clearTimeout(timeout);
                Toast.hide(callback);
            }.bind(null, timeout, callback);
        };
    })()

    static hide(callback) {
        var toast = document.getElementById(toastId);
        toast.onclick = null;
        toast.className = "";
        if (callback) {
            setTimeout(callback, 500);
        }
    }

    static _fetchClassName = function () {

    }

    render = function () {
        return (
            <div id={toastId}></div>
        );
    }
}

export default Toast;