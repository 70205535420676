import React, { Component } from "react";
import Keys from "../../Keys";
import "./styles.css";

const totalStars = 5;
const ratingStarImage = require("./rating_star.png");
const garbageImage = require("./garbage.png");

class InputRating extends Component {

    constructor(props) {
        super(props);
        this.state = { value: this.props.value }
    }

    _changeValue = function (newValue) {
        var oldValue = this.state.value;
        this.setState({ value: newValue });
        this.props.onChange && this.props.onChange(newValue, function () { // Fallback function as argument if something bad occurrs (fetch error etc)
            this.setState({ value: oldValue });
        }.bind(this));
    }

    _renderStars = function () {
        var stars = [];
        var validStars = this.state.value !== void 0 ? Math.round(this.state.value) : 0;
        var i;
        for (i = 0; i < validStars; i++) {
            stars.push(<img onClick={this._changeValue.bind(this, i + 1)} alt="Colored rating star representing a value" src={ratingStarImage} key={Keys.argsToKey("-rating-star-", i)} className={"InputRating-button"} />);
        }
        for (; i < totalStars; i++) {
            stars.push(<img onClick={this._changeValue.bind(this, i + 1)} alt="Grayed rating star representing void (no value)" src={ratingStarImage} key={Keys.argsToKey("-greyed-rating-star-", i)} className={"InputRating-button InputRating-grayscale-star"} />);
        }
        return stars;
    }

    _renderDelete = function () {
        return <img onClick={this._changeValue.bind(this, void 0)} alt="Remove rating" src={garbageImage} className={"InputRating-button"} />
    }

    render = function () {
        return (
            <div className="InputRating">
                {this._renderStars()}
                {this.state.value !== void 0 && this._renderDelete()}
            </div>
        );
    }
}

export default InputRating;