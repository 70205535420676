const config = require("./config");

var seed = config.starter;
var prefix = config.prefix;
var suffix = config.suffix;
var uniqueKey = void 0;

var generateUniqueKey = function () {
    return uniqueKey = prefix + hashCode(seed++).toString(36) + suffix;
};

var getCurrentUniqueKey = function () {
    return uniqueKey;
};

var hashCode = function (string) {
    var hash = 0;
    if (!string || !string.length) return hash;
    for (var i = 0; i < string.length; i++) {
        var char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

var argsToKey = function () {
    var string = prefix;
    for (var i = 0, len = arguments.length; i < len; i++) {
        string += hashCode(JSON.stringify({ [i]: arguments[i] })).toString(36);
    }
    return string + suffix;
};

export default {
    generateUniqueKey,
    getCurrentUniqueKey,
    argsToKey
};