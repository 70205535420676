import Data from "../Data";

const config = require("./config");

var dictionary = {};
var active;

var getBrowserLanguage = function () {
    return navigator.language || navigator.userLanguage || (navigator.languages && navigator.languages[0]) || config.defaultLanguage;
};

var getLanguagePackId = function (languages) {
    var browserLanguage = getBrowserLanguage().toLocaleUpperCase();
    var defaultLanguage = config.defaultLanguage.toLocaleUpperCase();
    var defaultLanguageId = -1;
    for (var i = 0, len = languages.length; i < len; i++) {
        var language = languages[i];
        // Found the user's browser language, so we return it and finish this function
        if (browserLanguage === language.description.toLocaleUpperCase()) {
            return language[config.languagePackProperty];
        }
        // In case we won't find the user's browser language, we already prepare
        // for the worst and look up for the default language on the same cicle, that way
        // we can use the same cicle, saving processing effort
        if (defaultLanguage.toLocaleUpperCase() === language.description.toLocaleUpperCase()) {
            defaultLanguageId = language[config.languagePackProperty];
        }
    }
    // If we're here, the user's browser language couldn't be found so we use the default's Id instead.
    return defaultLanguageId;
};

var getStorageLanguagePackId = function (languages) {
    var id = parseInt(localStorage.getItem(config.localStorageLangKey));
    if (isNaN(id) || id <= 0) {
        localStorage.removeItem(config.localStorageLangKey);
        return void 0;
    }
    for (var i = 0, len = languages.length; i < len; i++) {
        if (id === languages[i][config.languagePackProperty]) {
            return id;
        }
    }
    localStorage.removeItem(config.localStorageLangKey);
    return void 0;
};

var fetch = function (data, languageId, callback) {
    if (languageId && {}.toString.call(languageId) === "[object Function]") {
        callback = languageId;
        languageId = void 0;
    }
    var languagePackId = languageId || getStorageLanguagePackId(data[config.languages]) || getLanguagePackId(data[config.languages]);
    active = languagePackId;
    var toFetch = {
        endpoint: config.languagePackEndpoint.endpoint.replace("{languagePackId}", languagePackId),
        key: config.languagePackEndpoint.key
    };
    Data.fetchAsync([toFetch], function (err, receivedData) {
        if (err) {
            return callback(err);
        }
        data[config.keys].forEach(function (key) {
            var field = Data.first(receivedData[config.languagePackEndpoint.key], config.keyProperty, key.id);
            setField(key.description, field.value);
        });
        callback();
    });
};

var setField = function (key, value) {
    dictionary[key] = value;
};

export default {
    fetch,
    get: function (key) {
        var result = dictionary[key];
        if (result === void 0) {
            console.warn("Did not found dictionary word for key '" + key + "', using the key itself instead.");
            return key;
        }
        return result;
    },
    active: function () {
        return active;
    }
};
