const colorConverter = require("./lib/color-converter");
const config = require("./config");

var generate = function (length, saturation, lightness) {
    saturation = parseInt(saturation, 10) || config.defaultSaturation;
    lightness = parseInt(lightness, 10) || config.defaultLightness;
    return fetchArray(length, saturation, lightness);
};

var fetchArray = function (length, saturation, lightness) {
    var colors = [];
    var spacer = config.maxHue / length / 2;
    var current = 0;
    for (var i = 0; i < length; i++) {
        colors.push({ hue: Math.round(current % config.maxHue), saturation, lightness });
        current += (spacer + config.maxHue / 2);
    }
    return colors;
};

var generateRGB = function (length, saturation, lightness) {
    var result = generate(length, saturation, lightness);
    var converted = [];
    for (var i = 0; i < length; i++) {
        converted.push(colorConverter.hsl2rgb(result[i].hue, result[i].saturation, result[i].lightness));
    }
    return converted;
};

var generateHex = function (length, saturation, lightness) {
    var result = generate(length, saturation, lightness);
    var converted = [];
    for (var i = 0; i < length; i++) {
        converted.push(colorConverter.hsl2hex(result[i].hue, result[i].saturation, result[i].lightness));
    }
    return converted;
};

module.exports.generate = generate;
module.exports.generateRGB = generateRGB;
module.exports.generateHex = generateHex;