import React, { Component } from "react";
import Spinner from "../Spinner";
import Data from "../../Data";
import Toast from "../Toast/Toast";
import "./styles.css";

const Config = require("./config");

class WeatherWidget extends Component {

    constructor(props) {
        super(props);
        this.state = { index: 0, loaded: false, forecast: [] };
    }

    _sumIndex = function (index) {
        var current = this.state.index;
        this.setState({ index: current + index });
    }

    _load = function () {
        Data.fetchAsync(this.props.endpoints, function (err, data) {
            if (err) {
                return Toast.show(Config.fetchError, { type: "error" });
            }
            var forecast = this.props.dataToForecast(data);
            this.setState({ index: 0, loaded: true, forecast });
        }.bind(this));
    }

    componentWillMount = function () {
        !this.state.loaded && this._load.call(this);
    }

    _getTime = function (date) {
        var hours = date.getHours();
        hours < 10 && (hours = "0" + hours);
        var minutes = date.getMinutes();
        minutes < 10 && (minutes = "0" + minutes);
        return hours + ":" + minutes;
    }

    _getDate = function (date) {
        var day = date.getDate();
        day < 10 && (day = "0" + day);
        var month = date.getMonth() + 1;
        month < 10 && (month = "0" + month);
        return day + "/" + month;
    }

    _returnFirst = function () {
        this.setState({ index: 0 });
    }

    _goLast = function () {
        this.setState({ index: this.state.forecast.length - 1 });
    }

    _fetchReturnFirstButton = function () {
        if (this.state.index > 1) {
            return <p onClick={this._returnFirst.bind(this)} className="WeatherWidget-button">&lt;&lt;</p>;
        }
        return <p className="WeatherWidget-button WeatherWidget-buttonDisabled">&lt;&lt;</p>;
    }

    _fetchPreviousButton = function () {
        if (this.state.index > 0) {
            return <p onClick={this._sumIndex.bind(this, -1)} className="WeatherWidget-button">&lt;</p>;
        }
        return <p className="WeatherWidget-button WeatherWidget-buttonDisabled">&lt;</p>;
    }

    _fetchNextButton = function () {
        if (this.state.index < this.state.forecast.length - 1) {
            return <p onClick={this._sumIndex.bind(this, 1)} className="WeatherWidget-button">&gt;</p>;
        }
        return <p className="WeatherWidget-button WeatherWidget-buttonDisabled">&gt;</p>;
    }

    _fetchGoLastButton = function () {
        if (this.state.index < this.state.forecast.length - 2) {
            return <p onClick={this._goLast.bind(this)} className="WeatherWidget-button">&gt;&gt;</p>;
        }
        return <p className="WeatherWidget-button WeatherWidget-buttonDisabled">&gt;&gt;</p>;
    }

    _fetchSelectedWeather = function () {
        if (!this.state.loaded) {
            return <Spinner />;
        }
        var current = this.state.forecast[this.state.index];
        return (
            <div className="WeatherWidget-container" >
                <div className="WeatherWidget-dateNavigator">
                    {this._fetchReturnFirstButton()}
                    {this._fetchPreviousButton()}
                    {current.date && <p className="WeatherWidget-dateTime">{this._getDate(current.date) + " - " + this._getTime(current.date)}</p>}
                    {this._fetchNextButton()}
                    {this._fetchGoLastButton()}
                </div>
                <img className="WeatherWidget-image" src={this.props.iconBasePath + current.icon + ".svg"} alt="Weather" />
                {<p className="WeatherWidget-temperature">{current.temperature + "ºC"}</p>}
            </div >
        );
    }

    render = function () {
        return this._fetchSelectedWeather();
    }
}

export default WeatherWidget;