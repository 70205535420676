import React, { Component } from "react";
import "./styles.css";
import Config from "./config";

class Header extends Component {

    static Type = Config.Type;

    render() {
        var headerClass = this.props.type ? "Header-title" + this.props.type : "Header-titleRegular";
        (this.props.onLeftClick || this.props.onRightClick) && (headerClass += " Header-buttonsPadding");
        return (
            <header className={this.props.logo ? "Header-with-logo" : "Header"}>
                {this.props.logo && <img src={this.props.logo} className="Header-logo" alt={"Page logo for " + this.props.title} />}
                {this.props.onLeftClick && <button className="Header-link Header-left" onClick={this.props.onLeftClick}>{this.props.leftClickText || Config.Default.LEFT_TEXT}</button>}
                <h1 className={headerClass}>{this.props.title}</h1>
                {this.props.onRightClick && <button className="Header-link Header-right" onClick={this.props.onRightClick}>{this.props.rightClickText || Config.Default.RIGHT_TEXT}</button>}
            </header>
        );
    }
}

export default Header;