import React, { Component } from "react";
import Spinner from "../Spinner";
import "./styles.css";
const Config = require("./config");

class LoadingContainer extends Component {
    render() {
        return (
            <div>
                <div className="LoadingContainer-vertically-centered">
                    <p className="LoadingContainer-title">{this.props.title || Config.title}</p>
                    <Spinner />
                    <p>{this.props.subtitle || Config.subtitle}</p>
                </div>
            </div>
        );
    }
}

export default LoadingContainer;