import React, { Component } from "react";
import "./styles.css";

class StaticNavbarItem extends Component {

    render() {
        return (
            <li onClick={this.props.onClick} id={this.props.id} className={"StaticNavbarItem StaticNavbarItem-" + this.props.side}>
                <div className={this.props.active ? "StaticNavbarItem-active" : void 0}>
                    <span className="StaticNavbarItem-text">{this.props.alt} </span>
                    <img  className="StaticNavbarItem-icon" src={this.props.src} alt={this.props.alt} />
                </div>
            </li>
        );
    }
}

export default StaticNavbarItem;