import React, { Component } from "react";
import "./styles.css";

class Container extends Component {
    render = function () {
        return (
            <div className={this.props.textAlignCenter ? "Container Container-textAlignCenter" : "Container"}>{this.props.children}</div>
        );
    }
}

export default Container;
