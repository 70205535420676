import React, { Component } from "react";

import "./styles.css";

class Spinner extends Component {

    render = function () {
        return (
            <div className="Spinner">
                <div className="Spinner-bounce1"></div>
                <div className="Spinner-bounce2"></div>
                <div className="Spinner-bounce3"></div>
            </div>
        );
    }
}

export default Spinner;