

var multiBindSequential = function () {
    var functions = arguments;
    return function () {
        for (var i = 0, len = functions.length; i < len; i++) {
            functions[i] && functions[i]();
        }
    }
};

var multiBind = function () {
    var functions = arguments;
    return function () {
        Array.prototype.forEach.call(functions, function (fun) {
            fun && fun();
        });
    }
};

var isValidDate = function (date) {
    return date instanceof Date && !isNaN(date);
};

var isFunction = function (functionToCheck) {
    return functionToCheck && typeof functionToCheck === "function";
};

// https://stackoverflow.com/questions/4159838/html5-type-detection-and-plugin-initialization
var supportsInputType = function (type) {
    try {
        // Dummy input
        var tester = document.createElement("input");
        tester.type = type;
        tester.value = "(<1nv4l1d_type/>`";
        // Now we check if the type is still the given type
        // and if the type is supported, the entered value should
        // be invalid, so an empty string would be returned
        return tester.type === type && tester.value === "";
        // IE throws an error when assigning an 'invalid'
        // input type
    } catch (e) {
        return false;
    }
};

var trim = function (string, maxLength, endToken) {
    endToken = typeof endToken === "string" ? endToken : "";
    if (string.length > maxLength) {
        return (string.substring(0, maxLength - endToken.length)) + endToken;
    } else {
        return string;
    }
};

var removeHtml = function (html) {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
};

var randomInt = function (starter, range) {
    starter === void 0 && (starter = 0);
    range === void 0 && (range = 10);
    return Math.floor(Math.random() * range) + starter;
};

var isIE = navigator.userAgent.match(/Trident/g) || navigator.userAgent.match(/MSIE/g);
var isEdge = navigator.userAgent.match(/Edge/g);

export default {
    multiBindSequential,
    multiBind,
    randomInt,
    isFunction,
    trim,
    removeHtml,
    isIE,
    isEdge,
    supportsInputType,
    isValidDate
};
