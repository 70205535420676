import React, { Component } from "react";
import "./styles.css";

class Icon extends Component {

    _fetchImage = function () {
        return <img alt={this.props.alt || "Represents an icon"} src={this.props.src} className={"Icon-image"}/>
    }

    render() {
        return (
            <div className="Icon">
                {this.props.title && <p className="Icon-title">{this.props.title}</p>}
                {this.props.href ? <a href={this.props.href} target={"_blank"}>{this._fetchImage()}</a> : this._fetchImage()}
            </div>
        );
    }
}

export default Icon;