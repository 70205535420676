import Util from "../Util";

const config = require("./config");

var text = config.welcomeMessage;

if (Util.isIE || Util.isEdge) {
    // Remove format tokens and only save first index of the array (where the text is)
    // ignoring the rest (styles)
    text = [text[0].replace(/%c/g, "")];
}

export default function () {
    console.info.apply(null, text);
};