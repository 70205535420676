import React, { Component } from "react";
import { Icon } from "leaflet";
import { Map, TileLayer, Marker, Popup, Polyline } from "react-leaflet";
import LocateControl from 'react-leaflet-locate-control'
import Util from "../../Util";
import Config from "./config";
import Keys from "../../Keys";
import Spinner from "../Spinner";
import Data from "../../Data";
import Toast from "../Toast/Toast";
import "./styles.css";
import "./leaflet.css";
import { generateHex } from "../../equidistant-hue-color-array";

const BOUND_MARGIN = 0.1;

class MapView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            drawables: void 0,
            loaded: false
        };
    }

    _load = function () {
        Data.fetchAsync(this.props.endpoints, function (err, data) {
            if (err) {
                return Toast.show(Config.fetchError, { type: "error" });
            }
            var drawables = this.props.dataToDrawables(data);
            this.setState({ loaded: true, drawables });
        }.bind(this));
    }

    componentWillMount = function () {
        !this.state.loaded && this._load.call(this);
    }

    _fetchRandomTileURL = function () {
        var tileServers = this.props.tileServers || Config.tileServers;
        return tileServers[Util.randomInt(0, tileServers.length)];
    }

    _fetchPopupContent = function (polyline) {
        var content = [];
        for (var i = 0, len = polyline.popup.length; i < len; i++) {
            content.push(i === 0 && polyline.handler ? <button className={"MapView-link"} key={Keys.argsToKey("popup-link-for", polyline.popup[i], "i")} onClick={polyline.handler}>{polyline.popup[i]}</button> : polyline.popup[i]);
            i < len - 1 && content.push(<br key={Keys.argsToKey("line-break-for", polyline.popup[i], + "i")} />);
        }
        return content;
    }

    _fetchPolylines = function () {
        if (!this.state.drawables || !this.state.drawables.polylines) {
            return void 0;
        }
        var polylines = [];
        var colorArray = generateHex(this.state.drawables.polylines.length);
        for (var i = 0, lenI = this.state.drawables.polylines.length; i < lenI; i++) {
            var polyline = this.state.drawables.polylines[i];
            var positions = [];
            for (var j = 0, lenJ = polyline.length; j < lenJ; j++) {
                positions.push([polyline[j].lat, polyline[j].lng]);
            }
            if (!polyline.popup) {
                polylines.push(<Polyline onClick={polyline.handler} key={Keys.argsToKey("polyline", i, polyline.color || colorArray[i])} weight={9} opacity={0.8} color={polyline.color || colorArray[i]} positions={positions} />)
            } else {
                polylines.push(<Polyline key={Keys.argsToKey("polyline", ((polyline.popup && polyline.popup[0]) || ""), i, polyline.color || colorArray[i])} weight={9} opacity={0.8} color={polyline.color || colorArray[i]} positions={positions}>
                    {polyline.popup && <Popup><div>{this._fetchPopupContent(polyline)}</div></Popup>}
                </Polyline>);
            }
        }
        return polylines;
    }

    _fetchMarkers = function () {
        if (!this.state.drawables || !this.state.drawables.markers) {
            return void 0;
        }
        var markers = [];
        for (var i = 0, len = this.state.drawables.markers.length; i < len; i++) {
            var marker = this.state.drawables.markers[i];
            markers.push(<Marker key={Keys.argsToKey("marker", i, marker.lat, marker.lng)} icon={this._fetchIcon.call(this, marker.type)} position={[marker.lat, marker.lng]}>
                {marker.popup && <Popup><div>{marker.popup}</div></Popup>}
            </Marker>);
        }
        return markers;
    }

    _fetchIcon = function (color) {
        var defaultOptions = Icon.Default.prototype.options;
        return new Icon({
            iconUrl: require("./icons/" + (color ? color : "blue") + ".png"),
            shadowUrl: require("./icons/shadow.png"),
            iconSize: defaultOptions.iconSize,
            shadowSize: defaultOptions.shadowSize,
            iconAnchor: defaultOptions.iconAnchor,
            shadowAnchor: defaultOptions.shadowAnchor,
            popupAnchor: defaultOptions.popupAnchor
        });
    }

    _fetchBounds = function () {
        if (!this.state.drawables || !this.state.drawables.polylines) {
            return void 0;
        }
        var minLat = Number.POSITIVE_INFINITY;
        var maxLat = Number.NEGATIVE_INFINITY;
        var minLng = Number.POSITIVE_INFINITY;
        var maxLng = Number.NEGATIVE_INFINITY;
        for (var i = 0, lenI = this.state.drawables.polylines.length; i < lenI; i++) {
            var polyline = this.state.drawables.polylines[i];
            for (var j = 0, lenJ = polyline.length; j < lenJ; j++) {
                var coord = polyline[j];
                coord.lat > maxLat && (maxLat = coord.lat);
                coord.lat < minLat && (minLat = coord.lat);
                coord.lng > maxLng && (maxLng = coord.lng);
                coord.lng < minLng && (minLng = coord.lng);
            }
        }
        return [[minLat - BOUND_MARGIN, minLng - BOUND_MARGIN], [maxLat + BOUND_MARGIN, maxLng + BOUND_MARGIN]];
    }

    _onActivate = function () {
        this.setState({ active: true });
    }

    _onDeactivate = function () {
        this.setState({ active: false });
    }

    render() {
        if (!this.state.loaded) {
            return <div className="MapView-spinnerContainer"><Spinner /></div>;
        }
        var options = Config.locateControl;
        options.onActivate = this._onActivate.bind(this);
        options.onDeactivate = this._onDeactivate.bind(this);
        options.onLocationOutsideMapBounds = function () { };
        return (
            <div className="MapView">
                <Map
                    maxBounds={this._fetchBounds()}
                    maxZoom={this.props.maxZoom || Config.maxZoom}
                    minZoom={this.props.minZoom || Config.minZoom}
                    center={this.props.position || [Config.defaultPosition.lat, Config.defaultPosition.lng]}
                    zoom={this.props.zoom || Config.defaultPosition.zoom}
                    animate={true}
                >
                    <TileLayer
                        attribution={this.props.attribution || Config.attribution}
                        url={this._fetchRandomTileURL.call(this)}
                    />
                    {this._fetchPolylines.call(this)}
                    {this._fetchMarkers.call(this)}
                    {!this.props.hideLocationServices && <LocateControl options={Config.locateControl} />}
                </Map>
            </div >
        );
    }
}

export default MapView;