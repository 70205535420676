import React, { Component } from "react";
import Form from "../Form";
import FormField from "../Form/FormField";
import Header from "../Header";
import Container from "../Container";
import Data from "../../Data";
import FormRecaptcha from "../Form/FormRecaptcha";
import "./styles.css";

class SignupContainer extends Component {

    _signup = function (data) {
        if (!this._passwordsMatch(data)) {
            return this.props.onSignup("Passwords don't match", null);
        }
        this.props.dataToSignup && (data = this.props.dataToSignup(data));
        Data.signup(data, this.props.onSignup);
    }

    _passwordsMatch = function (data) {
        return data[this.props.passwordName || "password"] === data[this.props.passwordName ? this.props.passwordName + "Confirmation" : "passwordConfirmation"];
    }

    render = function () {
        return (
            <Container textAlignCenter={true}>
                <Header title={this.props.title} logo={this.props.logo} type={Header.Type.REGULAR} />
                <Form onSubmit={this._signup.bind(this)} submitValue={this.props.submitValue || this.props.title}>
                    <FormField id={this.props.emailName || "email"} label={this.props.emailLabel || "Email"} required={true} />
                    {this.props.children}
                    <FormField id={this.props.passwordName || "password"} label={this.props.passwordLabel || "Password"} type="password" required={true} />
                    <FormField id={this.props.passwordName ? this.props.passwordName + "Confirmation" : "passwordConfirmation"} label={this.props.passwordConfirmationLabel || "Password confirmation"} type="password" required={true} />
                    {this.props.recaptchaSiteKey && <FormRecaptcha id={this.props.recaptchaId || "recaptcha"} sitekey={this.props.recaptchaSiteKey} />}
                </Form>
                {this.props.text && <div className="SignupContainer-text">{this.props.text}</div>}
                {this.props.loginRedirect && <a className="SignupContainer-text" href={this.props.loginRedirect.href}>{this.props.loginRedirect.text}</a>}
            </Container>
        );
    }
}

export default SignupContainer;