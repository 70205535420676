import React, { Component } from "react";
import "./styles.css";

class Maintenance extends Component {

    render = function () {
        return (
            <img className="Maintenance-Main" alt={this.props.alt || "Under construction"} src={require("./under_construction.png")} />
        );
    }
}

export default Maintenance;