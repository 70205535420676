import React from "react";
import ListContainer from "./Components/ListContainer";
import HomeContainer from "./Components/HomeContainer";
import Icon from "./Components/Icon";
import LoginContainer from "./Components/LoginContainer";
import DetailsContainer from "./Components/DetailsContainer";
import Strings from "./Strings";
import HashRouter from "./HashRouter";
import Data from "./Data";
import NavigationContainer from "./Components/NavigationContainer";
// import NewsFeedContainer from "./Components/NewsFeedContainer";
import Keys from "./Keys";
import Utils from "./utils";
import Toast from "./Components/Toast/Toast";
import utils from "./utils";

const config = require("./config");
var refresh;

/**
 * HOME
 * - Home item is required as it will be the first page to be rendered.
 * ORDER MATTERS:
 * - Left items are placed from left to right (the first left item is the far-left item)
 * - Right items are placed from right to left (the first right item is the far-right item)
 * PROPERTIES:
 * - <key>:      Used both as an array key (required when having multiple JSX
 *               components on an array) and as the li element's id (just in case
 *               it will be used). As such, this property must be unique.
 * - name:       The user-friendly text to show.
 * - side:       The starter side where the item will be placed.
 * - renderable: The JSX component to render at the #root element when this item is
 *               pressed/touched.
 */

var createLanguageButton = (function () {
    var getLanguageHandler = function (languagePackId) {
        return function () {
            localStorage.setItem(config.localStorageLangKey, languagePackId);
            window.location.reload();
        };
    };
    return function (data, key, description) {
        var languagePackId = Data.where(data.languagePacks, "description", description)[0].id;
        return { key, handler: getLanguageHandler(languagePackId), active: languagePackId === Strings.active() };
    };
})();

var renderHome = function (items, data) {
    items[config.homeUrl] = {
        icon: config.navIcons.home,
        name: Strings.get("navHome"),
        side: "left",
        renderable:
            <HomeContainer
                languages={[
                    createLanguageButton(data, "pt", "PORTUGUESE"),
                    createLanguageButton(data, "en", "ENGLISH")
                ]}
                key={config.homeUrl}
                banner={config.homeBanner}
                bannerHandler={HashRouter.goTo.bind(null, [config.pathsUrl])}
                marketId={config.marketId}
                footer={[
                    <Icon key={Keys.argsToKey("servicedBy")} href={"http://www.vialusitana.org/"} alt="Via Lusitana's Icon" title={Strings.get("providedBy")} src={require("./res/via_lusitana.png")} />,
                    <Icon key={Keys.argsToKey("financedBy")} href={"http://www.turismodeportugal.pt"} alt="Turismo de Portugal's Icon" title={Strings.get("sponsoredBy")} src={require("./res/turismo_portugal.png")} />,
                    <Icon key={Keys.argsToKey("developedBy")} href={"http://www.cast.pt/"} alt="Cast's Icon" title={Strings.get("developedBy")} src={require("./res/cast.png")} />
                ]}
            />
    };
};

var renderPaths = function (items, data) {
    var paths = [];
    data.paths.forEach(function (path) {
        // @TODO: REMOVE BIT SUPPORT, ALLOW ONLY BOOLEAN SUPPORT
        var isActive;
        if (Array.isArray(path.active.data)) { // Bit array
            isActive = path.active.data[0];
        } else { // Boolean
            isActive = path.active;
        }
        if (isActive) {
            var nTracks = 0;
            var totalDistance = 0;

            var tracks = Data.where(data.tracks, "path", path.id);
            nTracks = tracks.length;
            tracks.forEach(function (track) {
                totalDistance += track.distance;
            });

            var from = Data.whereId(data.localizations, Data.whereId(data.points, path.start).localization).description;
            var to = Data.whereId(data.localizations, Data.whereId(data.points, path.end).localization).description;
            var nAccomodations = 0;
            Data.where(data.tracks, "path", path.id).forEach(function (track) {
                Data.where(data.trackPlaces, "track", track.id).forEach(function (trackPlace) {
                    Data.first(data.accomodations, "place", trackPlace.place) && nAccomodations++;
                });
            });

            paths.push({
                textLines: [path.description, from + " " + Strings.get("to") + " " + to + " (" + Math.round(totalDistance * 10) / 10 + " Km)", "" + nTracks + " " + Strings.get("stages") + " - " + nAccomodations + " " + Strings.get("lodges")],
                handler: HashRouter.goTo.bind(null, [config.pathsUrl, path.id]),
                logo: config.icons.paths,
                color: path.color
            });
        } else {
            paths.push({ textLines: [path.description, Strings.get("soonAvailable")], color: path.color });
        }
    });
    paths.length > 0 && (items[config.pathsUrl] = {
        name: Strings.get("navWays"),
        icon: config.navIcons.paths,
        side: "left",
        renderable:
            <ListContainer key={Keys.argsToKey("main-paths")}
                notAvailableText={Strings.get("soonAvailable")}
                title={Strings.get("navWays")}
                items={paths}
                bgImage={config.overviewPathsImage}
            />
    });
};

var renderDormitories = function (items, data) {
    var dormitories = [];
    data.accomodations.forEach(function (accomodation) {
        var place = Data.whereId(data.places, accomodation.place);
        var localization = Data.whereId(data.localizations, place.localization);
        var textLines = [localization.description, place.description];
        var contact = Utils.getMostImportantContact(Data.where(data.contacts, "place", place.id));
        textLines.push(contact);
        dormitories.push({
            logo: config.icons.accomodation,
            order: localization.order,
            textLines: textLines,
            handler: HashRouter.goTo.bind(null, [config.pointsUrl, place.id])
        });
    });

    dormitories.length > 0 && (items[config.dormitoriesUrl] = {
        name: Strings.get("navLodging"),
        icon: config.navIcons.dormitories,
        side: "left",
        renderable:
            <ListContainer key={Keys.argsToKey("main-dormitories")}
                title={Strings.get("navLodging")}
                items={dormitories}
                sorts={[
                    {
                        name: Strings.get("route"),
                        sort: function (a, b) {
                            var orderA = parseInt(a.order, 10);
                            var orderB = parseInt(b.order, 10);
                            if (!orderA) { return 1 };
                            if (!orderB) { return -1 };
                            return orderA - orderB;
                        }
                    },
                    {
                        name: Strings.get("location"),
                        sort: function (a, b) {
                            if (!a.textLines[0]) { return 1 };
                            if (!b.textLines[0]) { return -1 };
                            return a.textLines[0].localeCompare(b.textLines[0]);
                        }
                    },
                    {
                        name: Strings.get("name"),
                        sort: function (a, b) {
                            if (!a.textLines[1]) { return 1 };
                            if (!b.textLines[1]) { return -1 };
                            return a.textLines[1].localeCompare(b.textLines[1]);
                        }
                    }
                ]}
            />
    });
};

var renderFoods = function (items, data) {
    var foods = [];
    data.foods.forEach(function (food) {
        var place = Data.whereId(data.places, food.place);
        var localization = Data.whereId(data.localizations, place.localization);
        var textLines = [localization.description, place.description];
        var contact = Utils.getMostImportantContact(Data.where(data.contacts, "place", place.id));
        textLines.push(contact);
        foods.push({
            logo: config.icons.food,
            order: localization.order,
            textLines: textLines,
            handler: HashRouter.goTo.bind(this, [config.pointsUrl, place.id])
        });
    });

    foods.length > 0 && (items[config.foodsUrl] = {
        name: Strings.get("navFeeding"),
        icon: config.navIcons.foods,
        side: "left",
        renderable:
            <ListContainer key={Keys.argsToKey("main-foods")}
                title={Strings.get("navFeeding")}
                items={foods}
                sorts={[
                    {
                        name: Strings.get("route"),
                        sort: function (a, b) {
                            var orderA = parseInt(a.order, 10);
                            var orderB = parseInt(b.order, 10);
                            if (!orderA) { return 1 };
                            if (!orderB) { return -1 };
                            return orderA - orderB;
                        }
                    },
                    {
                        name: Strings.get("location"),
                        sort: function (a, b) {
                            if (!a.textLines[0]) { return 1 };
                            if (!b.textLines[0]) { return -1 };
                            return a.textLines[0].localeCompare(b.textLines[0]);
                        }
                    },
                    {
                        name: Strings.get("name"),
                        sort: function (a, b) {
                            if (!a.textLines[1]) { return 1 };
                            if (!b.textLines[1]) { return -1 };
                            return a.textLines[1].localeCompare(b.textLines[1]);
                        }
                    }
                ]}
            />
    });
};

var renderPoints = function (items, data) {
    var points = [];
    data.places.forEach(function (place) {
        var textLines = [Data.whereId(data.localizations, place.localization).description, place.description];
        var contact = Utils.getMostImportantContact(Data.where(data.contacts, "place", place.id));
        textLines.push(contact);
        if (Data.first(data.foods, "place", place.id)) {
        } else if (Data.first(data.accomodations, "place", place.id)) {
        } else {
            points.push({
                logo: utils.getLogo(data, place.place_type),
                textLines: textLines,
                handler: HashRouter.goTo.bind(null, [config.pointsUrl, place.id])
            });
        }
    });

    points.length > 0 && (items[config.otherUrl] = {
        name: Strings.get("navMiscellaneous"),
        icon: config.navIcons.other,
        side: "left",
        renderable:
            <ListContainer
                title={Strings.get("navMiscellaneous")}
                key={Keys.argsToKey("main-points")}
                items={points}
                sorts={[
                    {
                        name: Strings.get("route"),
                        sort: function (a, b) {
                            var orderA = parseInt(a.order, 10);
                            var orderB = parseInt(b.order, 10);
                            if (!orderA) { return 1 };
                            if (!orderB) { return -1 };
                            return orderA - orderB;
                        }
                    },
                    {
                        name: Strings.get("location"),
                        sort: function (a, b) {
                            if (!a.textLines[0]) { return 1 };
                            if (!b.textLines[0]) { return -1 };
                            return a.textLines[0].localeCompare(b.textLines[0]);
                        }
                    },
                    {
                        name: Strings.get("name"),
                        sort: function (a, b) {
                            if (!a.textLines[1]) { return 1 };
                            if (!b.textLines[1]) { return -1 };
                            return a.textLines[1].localeCompare(b.textLines[1]);
                        }
                    }
                ]}
            />
    });
};

var renderDataToDrawables = function (data) {
    return function (receivedData) {
        var polylines = [];
        var markers = [];
        data.paths.forEach(function (path) {
            var polyline = [];
            Data.iterate(Data.sortAsc(Data.where(data.tracks, "path", path.id), "order"), function (track, optionsA) {
                Data.iterate(Data.sortAsc(Data.where(data.routes, "track", track.id), "order"), function (route) {
                    Data.iterate(Data.sortAsc(Data.where(receivedData.routeCoordinates, "route", route.id), "order"), function (routeCoordinates, optionsB) {
                        (optionsB.i % 10 === 0 || optionsB.isLast || optionsB.isFirst) && polyline.push({ lat: routeCoordinates.latitude, lng: routeCoordinates.longitude });
                        optionsA.isFirst && optionsB.isFirst && markers.push({ type: "play", lat: routeCoordinates.latitude, lng: routeCoordinates.longitude });
                        optionsA.isLast && optionsB.isLast && markers.push({ type: "red_flag", lat: routeCoordinates.latitude, lng: routeCoordinates.longitude });
                    });
                });
            });
            if (polyline.length > 0) {

                var nTracks = 0;
                var totalDistance = 0;

                var tracks = Data.where(data.tracks, "path", path.id);
                nTracks = tracks.length;
                tracks.forEach(function (track) {
                    totalDistance += track.distance;
                });
                var from = Data.whereId(data.localizations, Data.whereId(data.points, path.start).localization).description;
                var to = Data.whereId(data.localizations, Data.whereId(data.points, path.end).localization).description;

                polyline.popup = [
                    path.description,
                    from + " " + Strings.get("to") + " " + to + " (" + Math.round(totalDistance * 10) / 10 + " Km)",
                    nTracks + " " + Strings.get("stages")
                ];
                polyline.handler = HashRouter.goTo.bind(null, [config.navUrl, path.id]);
                polylines.push(polyline);
            }
        });
        return { polylines, markers };
    }
};

var renderNav = function (items, data) {
    var dataToDrawables = renderDataToDrawables(data);
    items[config.navUrl] = {
        name: Strings.get("navNavigation"),
        icon: config.navIcons.nav,
        side: "left",
        renderable: <NavigationContainer
            dataToDrawables={dataToDrawables}
            endpoints={[
                { endpoint: "pth_route_coordinate", key: "routeCoordinates", cache: true }
            ]}
        />
    }
};

var renderLogin = function (items, init) {
    // @TODO: Put on strings db
    items[config.loginUrl] = {
        name: Strings.get("navLogin"),
        icon: config.navIcons.login,
        side: "right",
        renderable: <LoginContainer
            text={Strings.get("loginWelcomeText")}
            signupRedirect={{ text: Strings.get("noAccountCreateNow"), href: "#" + config.signupUrl }}
            title={Strings.get("loginTitle")}
            submitValue={Strings.get("loginSubmitValue")}
            loginName="username"
            loginLabel="Email"
            loginEndpoint={config.authenticationEndpoints.login}
            // dataToLogin={function (data) {
            //     return { username: data.username, password: data.password }
            // }}
            onLogin={function (err) {
                if (err) {
                    return Toast.show(Strings.get("loginError"), { type: "error" });
                }
                refresh();
                HashRouter.goTo("home");
                Toast.show(Strings.get("afterLogin"), { type: "success" });
            }}
        />
    };
};

var renderLogout = function (items, init) {
    items[config.logoutUrl] = {
        name: Strings.get("navLogout"),
        icon: config.navIcons.logout,
        side: "right",
        renderable: function () {
            Data.logout(function (err) {
                if (err) {
                    return Toast.show(Strings.get("logoutError"), { type: "error" });
                }
                refresh();
                HashRouter.goTo("home");
                Toast.show(Strings.get("afterLogout"), { type: "success" });
            });
        }
    }
};

var renderProfile = function (items, data) {
    var user = data.user[0];
    var buttons = [];
    buttons.push({
        value: Strings.get("editProfile"),
        onClick: HashRouter.goTo.bind(null, "edit")
    });
    buttons.push({
        value: Strings.get("deleteProfile"),
        onClick: HashRouter.goTo.bind(null, "delete")
    });
    items[config.profileUrl] = {
        name: Strings.get("navProfile"),
        icon: config.navIcons.profile,
        side: "right",
        renderable: <DetailsContainer
            title={Strings.get("navProfile")}
            items={[
                { label: Strings.get("profileEmail"), textContent: user.email },
                // { label: Strings.get("LANGUAGE"), textContent: Strings.get(user.language ? Data.whereId(data.languagePacks, user.language).description : Strings.get("AUTO")) }
            ]}
            buttons={buttons}
        />
    };
};

export default function (data, init) {
    var items = { home: void 0 };
    refresh = function () {
        Data.loadData(function (err, data) {
            if (err) {
                return Toast.show("Error", { type: "error" });
            }
            init(data);
        });
    };

    /**
     * Home page
     */
    renderHome(items, data);

    if (!data) {
        return items;
    }

    /**
     * Paths page
     */
    renderPaths(items, data);

    /**
     * Dormitories page
     */
    renderDormitories(items, data);

    /**
     * Foods page
     */
    renderFoods(items, data);

    /**
     * Points page
     */
    renderPoints(items, data);

    /**
     * Nav page
     */
    renderNav(items, data);

    /**
     * News feed page
     */
    // renderNewsFeed(items)

    if (Data.getUser()) {

        /**
         * Logout page
         */
        renderLogout(items);

        /**
         * Profile page
         */
        renderProfile(items, data);
    } else {

        /**
         * Login page
         */
        renderLogin(items);
    }
    return items;
}
