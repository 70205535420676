export default function () {
    var location = window.location;
    if (location.href.indexOf("localhost") === -1 && location.href.indexOf("127.0.0.1") === -1 && location.href.indexOf("192.168.1.") === -1) {
        if (location.protocol) {
            location.protocol === "http:" && (location.protocol = "https:");
        } else {
            location.href.replace("http:", "https:");
        }
    }
};
