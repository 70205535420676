import React, { Component } from "react";
import Form from "../Form";
import FormField from "../Form/FormField";
import Header from "../Header";
import Container from "../Container";
import Data from "../../Data";
import Intro from "../Intro";

class SignupContainer extends Component {

    _delete = function (data) {
        this.props.dataToDelete && (data = this.props.dataToDelete(data));
        Data.del(data, this.props.onDelete);
    }

    render = function () {
        return (
            <Container textAlignCenter={true}>
                <Header title={this.props.title} logo={this.props.logo} type={Header.Type.REGULAR} />
                {this.props.warningMessage && <Intro>{this.props.warningMessage}</Intro>}
                <Form onSubmit={this._delete.bind(this)} submitValue={this.props.submitValue || this.props.title}>
                    {this.props.children}
                    <FormField id={this.props.passwordName || "password"} label={this.props.passwordLabel || "Password"} type="password" required={true} />
                </Form>
            </Container>
        );
    }
}

export default SignupContainer;