import React, { Component } from "react";
import Form from "../Form";
import FormField from "../Form/FormField";
import Data from "../../Data";
import Header from "../Header";
import Container from "../Container";
import "./styles.css";

class LoginContainer extends Component {

    _login = function (data) {
        this.props.dataToLogin && (data = this.props.dataToLogin(data));
        Data.login(data, this.props.onLogin);
    }

    render = function () {
        return (
            <Container textAlignCenter={true}>
                <Header title={this.props.title} logo={this.props.logo} type={Header.Type.REGULAR} />
                <Form onSubmit={this._login.bind(this)} submitValue={this.props.submitValue || this.props.title}>
                    <FormField id={this.props.loginName || "login"} label={this.props.loginLabel} required={true} />
                    <FormField id={this.props.passwordName || "password"} label={this.props.passwordLabel || "Password"} type="password" required={true} />
                </Form>
                {this.props.text && <div className="LoginContainer-text">{this.props.text}</div>}
                {this.props.signupRedirect && <a className="LoginContainer-text" href={this.props.signupRedirect.href}>{this.props.signupRedirect.text}</a>}
            </Container>
        );
    }
}

export default LoginContainer;